import React, {useEffect, useMemo, useRef, useState} from 'react';
import gsap from "gsap";
import {useSwipeable} from "react-swipeable";

const ScrollBox = ({children,mobile=true,widths=200}) => {
    const scrollBox=useRef();
    const swipInfo=useRef({isSwiping:false,x:0,y:0});
    const [childrenwidth, setChildrenwidth] = useState(1);
    const [pst, setPst] = useState(0);
    const config={
        // delta: { left: 20, right: 20 },                             // min distance(px) before a swipe starts. *See Notes*
        preventScrollOnSwipe: false,           // prevents scroll during swipe (*See Details*)
        trackTouch: mobile?true:false,                      // track touch input
        trackMouse: mobile?false:true,                     // track mouse input
        rotationAngle: 0,                      // set a rotation angle
        swipeDuration: Infinity,               // allowable duration of a swipe (ms). *See Notes*
        touchEventOptions: { passive: false },  // options for touch listeners (*See Details*)
    }
    const handlers = useSwipeable({
        onTouchStartOrOnMouseDown:(e)=>{
            gsap.killTweensOf(scrollBox.current);
            swipInfo.current.x=gsap.getProperty(scrollBox.current,"x");
            swipInfo.current.y=gsap.getProperty(scrollBox.current,"y");
        },
        onSwipeStart:(eventData)=>{
            swipInfo.current.x=gsap.getProperty(scrollBox.current,"x");
            swipInfo.current.y=gsap.getProperty(scrollBox.current,"y");
        },
        onSwiping:(eventData)=>{
            eventData.event.stopPropagation();
            if(eventData.first){
                if(eventData.dir==="Left"||eventData.dir=="Right"){
                    swipInfo.current.isSwiping=true;
                }
            }
            if(swipInfo.current.isSwiping&&eventData.event.cancelable){
                eventData.event.preventDefault();
                gsap.set(scrollBox.current,{x:(swipInfo.current.x+eventData.deltaX)});

                setPst(gsap.getProperty(scrollBox.current,"x")/(widths-childrenwidth));
            }
        },
        onSwiped:(eventData)=>{
            swipInfo.current.isSwiping=false;
            let www=widths;
            let width=gsap.getProperty(scrollBox.current,"width");
            let x=gsap.getProperty(scrollBox.current,"x");
            let speed=eventData.velocity/2;
            if(x>0){
                gsap.to(scrollBox.current,{duration:.3,x:0,onUpdate:()=>{
                    setPst(gsap.getProperty(scrollBox.current,"x")/(widths-childrenwidth));
                }});
            }else if(x<(www-width)){
                gsap.to(scrollBox.current,{duration:.3,x:www-width,onUpdate:()=>{
                    setPst(gsap.getProperty(scrollBox.current,"x")/(widths-childrenwidth));
                }});
            }else{
                if(speed<.3){
                    return;
                }
                let tg=x+eventData.deltaX*2;
                if(tg>0){
                    tg=0;
                }else if(tg<(www-width)){
                    tg=(www-width);
                }
                gsap.to(scrollBox.current,{duration:speed,x:tg,onUpdate:()=>{
                    setPst(gsap.getProperty(scrollBox.current,"x")/(widths-childrenwidth));
                }});
            }

        },
        ...config,
    });
    useEffect(()=>{
        setChildrenwidth(scrollBox.current.firstChild.offsetWidth);
    },[]);
    const jindu=useMemo(()=>{
        let parw=widths*.3;
        let mew=(widths/childrenwidth)*parw;
        let movexs=Math.ceil((parw-mew)*pst);
        return(
            <div style={{width:Math.ceil(mew),
                transform:`translateX(${movexs}px)`,
                height:"100%",backgroundColor:'#ffffff',
                borderRadius:100,
            }}></div>
        )
    },[pst,childrenwidth]);
    return (
        <div {...handlers} style={{position:'relative',width:widths,overflow:'hidden'}}>
            <div style={{width:childrenwidth,position:'relative',boxSizing:'border-box'}} ref={scrollBox}>{children}</div>
            <div style={{
                margin:"2rem auto 0 auto",
                overflow:'hidden',
                width:widths*.3,height:16,backgroundColor:'rgba(238, 238, 238, 0.2)',borderRadius:100}}>
                {jindu}
            </div>
        </div>
    );
};

export default ScrollBox;