import React from 'react';
import AnimateScroll from "../../component/animateScroll/animateScroll";
import {appUrls} from './config';

const DashboardM = () => {
    return (<>
        <div className='appM bg' style={{backgroundImage: 'url(./img/common/app/bg.jpg)'}}></div>
        <div className='appM phoneanimate'>
            <AnimateScroll aniamteType={6}><img className={'phone1'} src="./img/common/app/phone1.png" /></AnimateScroll>
            <AnimateScroll aniamteType={3}><img className={'phone2'} src="./img/common/app/phone2.png" /></AnimateScroll>
            <div className='shadow'></div>
            <div className='bar'>
                {!appUrls.isAndroid ? <a className='downloadbtn half' href={appUrls.iOSAppStore} target='_blank'>
                    <svg width="34" height="42" viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M33.3362 30.4993C31.274 29.4777 29.7186 27.9562 28.81 25.8254C28.1562 24.292 27.9949 22.6826 28.1734 21.0312C28.3393 19.4969 28.8765 18.1121 29.8108 16.884C30.5111 15.9634 31.3777 15.2242 32.3415 14.5936C32.5766 14.4398 32.5781 14.4376 32.4094 14.2037C32.0702 13.7334 31.6912 13.2984 31.2689 12.8996C29.4739 11.2044 27.283 10.476 24.8749 10.301C23.6253 10.2104 22.4182 10.4755 21.2412 10.8684C20.2498 11.1993 19.2605 11.5404 18.2879 11.9222C17.4591 12.2475 16.6681 12.2409 15.8308 11.9393C14.8409 11.5828 13.8673 11.1794 12.8608 10.87C11.8713 10.5659 10.8718 10.3412 9.82181 10.4177C8.92616 10.4829 8.05197 10.6403 7.20985 10.9505C4.17394 12.0683 2.16658 14.2269 0.968301 17.1808C0.245662 18.9621 -0.0245301 20.83 0.00384843 22.8459C-0.0195817 23.7514 0.0658519 24.7496 0.208937 25.7443C0.590259 28.3945 1.40781 30.9096 2.54456 33.3274C3.40134 35.1497 4.53064 36.7975 5.7669 38.3762C6.47016 39.2744 7.25367 40.0947 8.18867 40.7592C9.33717 41.5753 10.592 41.8155 11.9616 41.4699C12.6734 41.2903 13.3615 41.0458 14.0391 40.7672C15.0638 40.3461 16.1193 40.0453 17.231 39.9827C18.5908 39.9062 19.8704 40.2437 21.1126 40.7592C21.7704 41.0321 22.4404 41.2648 23.1349 41.428C24.5395 41.7579 25.8461 41.5447 27.0504 40.7413C27.7463 40.2771 28.3462 39.705 28.8821 39.0682C30.9166 36.6502 32.5348 33.9913 33.5328 30.9765C33.642 30.6469 33.6451 30.6524 33.3362 30.4993Z" fill="black"/>
                    <path d="M16.9578 9.9382C18.035 9.98452 19.0667 9.80108 20.0439 9.33665C21.2474 8.76466 22.2107 7.89846 23.0274 6.86366C24.3312 5.21168 25.0855 3.35342 25.0856 1.22354C25.0856 1.0841 25.0879 0.944647 25.0852 0.805317C25.0768 0.359548 25.0433 0.341901 24.5958 0.400804C23.5304 0.540968 22.546 0.914778 21.6033 1.41343C19.4121 2.57254 17.9368 4.35091 17.1047 6.66799C16.7499 7.65593 16.6346 8.68466 16.7322 9.73466C16.7467 9.89098 16.8183 9.93224 16.9578 9.9382Z" fill="black"/>
                    </svg>
                    <small>Download from</small>
                    <b>App Store</b>
                </a> : undefined}
                {!appUrls.isAndroid ? <a className='downloadbtn half' href={appUrls.iOSTestflight} target='_blank'>
                    <svg width="34" height="42" viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M33.3362 30.4993C31.274 29.4777 29.7186 27.9562 28.81 25.8254C28.1562 24.292 27.9949 22.6826 28.1734 21.0312C28.3393 19.4969 28.8765 18.1121 29.8108 16.884C30.5111 15.9634 31.3777 15.2242 32.3415 14.5936C32.5766 14.4398 32.5781 14.4376 32.4094 14.2037C32.0702 13.7334 31.6912 13.2984 31.2689 12.8996C29.4739 11.2044 27.283 10.476 24.8749 10.301C23.6253 10.2104 22.4182 10.4755 21.2412 10.8684C20.2498 11.1993 19.2605 11.5404 18.2879 11.9222C17.4591 12.2475 16.6681 12.2409 15.8308 11.9393C14.8409 11.5828 13.8673 11.1794 12.8608 10.87C11.8713 10.5659 10.8718 10.3412 9.82181 10.4177C8.92616 10.4829 8.05197 10.6403 7.20985 10.9505C4.17394 12.0683 2.16658 14.2269 0.968301 17.1808C0.245662 18.9621 -0.0245301 20.83 0.00384843 22.8459C-0.0195817 23.7514 0.0658519 24.7496 0.208937 25.7443C0.590259 28.3945 1.40781 30.9096 2.54456 33.3274C3.40134 35.1497 4.53064 36.7975 5.7669 38.3762C6.47016 39.2744 7.25367 40.0947 8.18867 40.7592C9.33717 41.5753 10.592 41.8155 11.9616 41.4699C12.6734 41.2903 13.3615 41.0458 14.0391 40.7672C15.0638 40.3461 16.1193 40.0453 17.231 39.9827C18.5908 39.9062 19.8704 40.2437 21.1126 40.7592C21.7704 41.0321 22.4404 41.2648 23.1349 41.428C24.5395 41.7579 25.8461 41.5447 27.0504 40.7413C27.7463 40.2771 28.3462 39.705 28.8821 39.0682C30.9166 36.6502 32.5348 33.9913 33.5328 30.9765C33.642 30.6469 33.6451 30.6524 33.3362 30.4993Z" fill="black"/>
                    <path d="M16.9578 9.9382C18.035 9.98452 19.0667 9.80108 20.0439 9.33665C21.2474 8.76466 22.2107 7.89846 23.0274 6.86366C24.3312 5.21168 25.0855 3.35342 25.0856 1.22354C25.0856 1.0841 25.0879 0.944647 25.0852 0.805317C25.0768 0.359548 25.0433 0.341901 24.5958 0.400804C23.5304 0.540968 22.546 0.914778 21.6033 1.41343C19.4121 2.57254 17.9368 4.35091 17.1047 6.66799C16.7499 7.65593 16.6346 8.68466 16.7322 9.73466C16.7467 9.89098 16.8183 9.93224 16.9578 9.9382Z" fill="black"/>
                    </svg>
                    <small>Download</small>
                    <b>iOS Beta</b>
                    <span>TestFlight</span>
                </a> : undefined}
                {!appUrls.isiOS ? <a className='downloadbtn full nosubtitle' href={appUrls.AndroidPlayStore} target='_blank'>
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.13117 40.6236L21.7821 21.0189L2.13117 1.37648C1.64719 2.15759 1.3125 3.42259 1.3125 5.24491V36.7543C1.3125 38.5769 1.64719 39.8418 2.13117 40.6236ZM30.0815 29.3135L23.6425 22.8414L4.51279 42H4.69932C6.33659 42 7.75089 41.404 10.0209 40.1764L30.0815 29.3135ZM32.4996 13.9874L25.5029 21.0189L32.4996 28.0126L36.7424 25.7057C38.3052 24.8496 40.6875 23.2874 40.6875 21.0189C40.6875 18.712 38.3052 17.1497 36.7423 16.2936L32.4996 13.9874ZM4.51279 0L23.6425 19.1581L30.0815 12.6853L10.0209 1.82249C7.75089 0.595301 6.33667 0 4.69932 0H4.51279Z" fill="black"/>
                    </svg>
                    <b>Download from Google Play</b>
                </a> : undefined}
            </div>
        </div>
        <div className={'appM content'}>
            <AnimateScroll aniamteType={2}><div className={'bannerTextInfo title'}>
                Download <span className='gradient-text'>SignalPlus</span> APP<br />
                Trade Anytime Anywhere<br />
                With Just a Tap
            </div></AnimateScroll>
            <div className='bodybg' />
        </div>
        </>
    );
};

export default DashboardM;