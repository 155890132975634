/*
* 非视频数据结构
* {img:"",title:"",infoList:[{text:"",x:"",y:""}]}
* */
const posScale=(pos=0)=>{
    return Math.floor(740/1480*pos);
}
const carouselData=[
    {img:"./img/common/dashboard/dashboard.png",title:"Dashboard",infoList:[
            {text:"Widgets can be toggled on/off and adjusted in different orders and sizes",pos:[{x:posScale(632),y:posScale(25)}]},
            {text:"Use our pre-designed views or create your own trading workspaces to fit your specific needs",pos:[{x:posScale(53),y:posScale(575)}]},
            {text:"Customize your workspace by adding different widgets",pos:[{x:posScale(1347),y:posScale(31)}]}]
    },
    {img:"./img/common/dashboard/smart.png",title:"Smart Dealing",infoList:[
        {text:"Simply select from pre-built strategies or customize your own",pos:[{x:posScale(48),y:posScale(164)}]},
        {text:"Present all relevant info you need to place a multi-leg order in the same view",pos:[{x:posScale(446),y:posScale(52)}]},
        {text:"Break down large orders into smaller trades to minimize impact to the market",pos:[{x:posScale(671),y:posScale(307)}]},
        {text:"Support customized schedule for execution",pos:[{x:posScale(1160),y:posScale(44)}]},
        {text:"Neutralize \n Delta, Gamma, Vega with just one click",pos:[{x:posScale(1333),y:posScale(116)}]},
        {text:"Review Greeks and PnL diagram before executing the strategy",pos:[{x:posScale(1405),y:posScale(240)}]},
        {text:"Place dynamic limit order as the market moves to capture the most efficient pricina",pos:[{x:posScale(1240),y:posScale(565)}]}]
    },
    {img:"./img/common/dashboard/live.png",title:"Live Risk / PnL",infoList:[
            {text:"Breakdown of different risk and\n" +
                    "PL attribution factors helps you manage your exposure wisely",pos:[{x:posScale(48),y:posScale(172)}]},
            {text:"Leverage advanced analysis tools to review portfolio decay in a more granular view",pos:[
                {x:posScale(849),y:posScale(478)},
                {x:posScale(1242),y:posScale(526)}]}]
    },
    {img:"./img/common/dashboard/volatility.png",title:"Volatility Lab",infoList:[
            {text:"Utilize a comprehensive suite of volatility modules to identify \n IV patterns and opportunities across different strikes and expiries",
                pos:[{x:posScale(587),y:posScale(108)}]}]
    },
    {img:"./img/common/dashboard/risk.png",title:"Risk Scenario",infoList:[
            {text:"Define different underlying price scenarios to conduct simulation",pos:[{x:posScale(139),y:posScale(71)}]},
            {text:"Project daily risk factors and PrL based on your defined scenarios",pos:[
                {x:posScale(168),y:posScale(360)},
                {x:posScale(163),y:posScale(544)}
                ]},
            {text:"Filter instruments by expiry date range to simulate PhL",pos: [{x:posScale(740),y:posScale(71)}]},
        ]
    },
    {img:"./img/common/dashboard/dynamic.png",title:"Dynamic Delta Hedge",infoList:[
            {text:"User-defined tolerance levels and order mechanism",pos:[
                {x:posScale(572),y:posScale(128)},
                {x:posScale(548),y:posScale(428)}
                ]},
            {text:"The portfolio's delta will be maintained within desired level automatically",pos:[{x:posScale(871),y:posScale(629)}]}
        ]
    },
];




import React from 'react';

class CarouselDataaAnalyze {
    constructor(data) {
        this.data=data;
    }
    length=()=>{
        return this.data.length;
    }

    smallLength=(id)=>{
        return this.data[id].infoList.length;
    }

    getImageURL=(id)=>{
        return this.data[id].img;
    }

    getTitle=(id)=>{
        return this.data[id].title;
    }

    getSmallInfo=(id,smallid)=>{
        return this.data[id].infoList[smallid];
    }
}

const CarouselData=new CarouselDataaAnalyze(carouselData);

export {CarouselData,CarouselDataaAnalyze};