import React, { useMemo, useRef, useState} from 'react';
import './mCarouselType1.scss';
import {CarouselData} from '../../pc/carouselType1/data'
import gsap from "gsap";
import useRem from "../../../common/useRem";
import {useSwipeable} from "react-swipeable";
import ScaleAnimate from "./scaleAnimate";

const MCarouselType1 = ({imgwidth=740,imageheight=100,dataArray=CarouselData,style={}}) => {
    const banner=useRef();
    const [nowId, setNowId] = useState(0);
    const xs=useRem();
    const swipInfo=useRef({isSwiping:false,x:0,y:0});
    const candrag=useRef(false);

    const itemsRef = useRef([]);
    const config={
        delta: { left: 20, right: 20 },                             // min distance(px) before a swipe starts. *See Notes*
        preventScrollOnSwipe: false,           // prevents scroll during swipe (*See Details*)
        trackTouch: true,                      // track touch input
        trackMouse: false,                     // track mouse input
        rotationAngle: 0,                      // set a rotation angle
        swipeDuration: Infinity,               // allowable duration of a swipe (ms). *See Notes*
        touchEventOptions: { passive: false },  // options for touch listeners (*See Details*)
    }

    const handlers = useSwipeable({
        onSwipeStart:(eventData)=>{
            if(eventData.event.target.closest(".infoChangeScrollPar")){
                candrag.current=false;
            }else{
                candrag.current=true;
            }
            swipInfo.current.x=gsap.getProperty(banner.current,"x");
            swipInfo.current.y=gsap.getProperty(banner.current,"y");
        },
        onSwiping:(eventData)=>{
            if(!candrag.current){
                return;
            }
            eventData.event.stopPropagation();
            if(eventData.first){
                if(eventData.dir==="Left"||eventData.dir=="Right"){
                    swipInfo.current.isSwiping=true;
                }
            }
            if(swipInfo.current.isSwiping&&eventData.event.cancelable){
                eventData.event.preventDefault();
                gsap.set(banner.current,{x:(swipInfo.current.x+eventData.deltaX)});
            }
        },
        onSwiped:(eventData)=>{
            if(!candrag.current){
                return;
            }
          swipInfo.current.isSwiping=false;
          let www=window.innerWidth;
          let xss=eventData.deltaX>0?-1:1;
          let pst=Math.abs(eventData.deltaX)/www;
          if(pst>.2){
              let id;
              if(xss>0){
                  id=nowId+1;
              }else{
                  id=nowId-1;
              }
              if(id<0||id>(dataArray.length()-1)){
                  gsap.to(banner.current,{x:nowId*www*-1,duration:.3});
              }else {
                  gsap.to(banner.current,{x:id*-1*www,duration:.3,onStart:()=>{setNowId(id);}});
              }
          }else{
              gsap.to(banner.current,{x:nowId*www*-1,duration:.3});
          }
        },
        ...config,
    });
    /*const getMoveX=()=>{
        let showWidth=Math.floor(xs*327);
        let nowMaxWidth=dataArray.smallLength(nowId)*Math.floor(xs*145+8)-showWidth;
        let width=Math.floor(xs*145)+8;
        let returnX=showWidth/2-width*nowSmallId-width/2;

        let mmm=Math.floor(xs*145+8)*dataArray.smallLength(nowId);
        if(mmm<=showWidth){
            return  (showWidth-mmm)/2
        }

        if(returnX>0){
            returnX=0;
        }else if(Math.abs(returnX)>nowMaxWidth){
            returnX=nowMaxWidth*-1;
        }

        return returnX;
    }*/
    const listContent=useMemo(()=>{
        let ary=[];
        for(let i=0;i<dataArray.length();i++){

            ary.push(
                <div key={i} className={"bg"} style={{width:Math.floor(xs*327)}}>
                    <div className={"title"}>{dataArray.getTitle(i)}</div>
                    <ScaleAnimate
                        i={i}
                        imageheight={imageheight} dataArray={dataArray} nowId={nowId}
                        imgwidth={imgwidth}/>

                    {/*<div className={'leftRightControl'}>
                        <div onClick={()=>{clickToSmallChange(nowSmallId-1)}} className={'leftButton'}><img src={`./img/common/${nowSmallId<=0?"left":"leftlight"}.png`} /></div>
                        <div onClick={()=>{clickToSmallChange(nowSmallId+1)}} className={'rightButton'}><img src={`./img/common/${nowSmallId>=(dataArray.smallLength(i)-1)?"right":"rightlight"}.png`} /></div>
                    </div>*/}
                </div>
            )
        }
        return ary;
    },[nowId]);



    const navList=useMemo(()=>{
        let ary=[];
        for(let i=0;i<dataArray.length();i++){
            ary.push(
                <div key={i} className={`icon ${nowId===i?"froce":""}`}>
                    {nowId===i&&dataArray.getTitle(nowId)}
                </div>
            )
        }
        return(
            <div className={'contentChange'}>
                {ary}
            </div>
        )
    },[nowId]);

    return (
        <div {...handlers} style={{...style}} className={'carouselType1'}>
            <div ref={banner} className={'listFlex'} style={{width:window.innerWidth*dataArray.length()}}>
                {listContent}
            </div>
            {navList}
        </div>
    );
};

export default MCarouselType1;