import React from 'react';
import './company.scss'
import {BrowserView, MobileView} from "react-device-detect";
import CompanyP from "./companyP";
import CompanyM from "./companyM";
const Company = () => {
    return (
        <>
            <BrowserView>
                <CompanyP/>
            </BrowserView>
            <MobileView>
                <CompanyM/>
            </MobileView>
        </>
    );
};

export default Company;