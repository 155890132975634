import React from 'react';
import './content.scss'
import {BrowserView, MobileView} from "react-device-detect";
import ContentP from "./contentP";
import ContentM from "./contentM";
const Content = () => {
    return (
        <>
            <BrowserView>
                <ContentP/>
            </BrowserView>
            <MobileView>
                <ContentM/>
            </MobileView>
        </>
    );
};

export default Content;