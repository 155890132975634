import React, {useEffect, useMemo, useRef} from 'react';
import './animateScroll.scss';
import {gsap} from "gsap";
import {isMobile} from "react-device-detect";
const AnimateScroll = ({children,classname='',aniamteType=0,obo=0}) => {
    const ref=useRef();
    const refanimate=useRef();
    useEffect(()=>{
        let animateobj={opacity:0};
        switch (aniamteType){
            case 0:
                animateobj.y="+=100";
                break;
            case 1:
                animateobj.y="-=100";
                break;
            case 2:
                animateobj.x="-=100";
                break;
            case 3:
                animateobj.x="+=100";
                break;
            case 5:
                animateobj.x="-=50";
                break;
            case 6:
                animateobj.x="+=50";
                break;
            case 4:
                animateobj.scale="0";
        }
        gsap.set(refanimate.current,animateobj);
        let ctx = gsap.context(() => {
            gsap.to(refanimate.current,{
                x:0,
                y:0,
                scale:1,
                opacity:1,
                duration:1,
                scrollTrigger:{
                    scrub:1.5,
                    trigger:ref.current,
                    start:`${25*obo}% 90%"}`,
                    end:`${25*obo}% 60%`,
                    // markers:true,

                }
            })
        }, ref); // <- IMPORTANT! Scopes selector text
        return () => ctx.kill(); // cleanup
    },[]);
    const reactMemo=useMemo(()=>{
        /*let html=<div className={'animateScrollPar'}><div className={'animateScroll'} ref={ref}>{children}</div></div>;
        if(aniamteType===0||aniamteType===1){
            html=<div className={'animateScroll'} ref={ref}>{children}</div>;
        }*/
        return (<div ref={ref} className={`animateScrollPar ${classname}`}><div className={'animateScroll'} ref={refanimate}>{children}</div></div>)
    },[]);
    return reactMemo;
};

export default AnimateScroll;