import React from 'react';
import ReactDOM from 'react-dom/client';
import './common/style.scss';
import reportWebVitals from './reportWebVitals';
import RouterConfig from "./router/routerConfig";
import {RouterProvider} from "react-router-dom";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const root = ReactDOM.createRoot(document.getElementById('root'));

function BrowserRouter(props) {
    return null;
}

root.render(
  <React.StrictMode>
      <RouterProvider router={RouterConfig} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
