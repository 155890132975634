import React from 'react';
import LightAnimate from "../../component/lightAnimate";
import AnimateScroll from "../../component/animateScroll/animateScroll";
import ClickToSelect from "./clickToSelect";
import {toast} from "react-toastify";
import {isChinese} from "../../common/tool";

const ContactusP = () => {
    const onSelect=()=>{
        document.execCommand('copy', true);
        toast.success(isChinese()?"复制成功":"copy success");
    }
    return (
        <div className={'contentpc contactusP'}>
            <AnimateScroll><div className={'bannerTextInfo'}>Get in Touch</div></AnimateScroll>
            <div className={'contactusFlex'}>
                <AnimateScroll aniamteType={2}><div className={'image'}><img alt={''} src={'./img/common/contactus.png'} /></div></AnimateScroll>
                <AnimateScroll aniamteType={3}><p>We love hearing from you! Whether you have a question, a suggestion, or just want to say hello, don't hesitate to reach out to us. Our team is here to assist and we always value your feedback.Let's start a conversation today and see how we can help you achieve your goals. We are available via any of the communication channels below and we look forward to hearing from you soon!</p></AnimateScroll>
            </div>

            <div className={"introbox"}>
                <AnimateScroll obo={0}>
                    <div className={'card'}>
                        <div className={'icon'}><img alt={''} src={'./img/common/contact/0.png'} /></div>
                        <div className={'title'}>Product Enquiries</div>
                        <ClickToSelect onSelect={onSelect}><div className={'more'}>BD@signalplus.com<img alt={''} src={'./img/common/contact/icon.png'}/></div></ClickToSelect>
                    </div>
                </AnimateScroll>
                <AnimateScroll obo={1}>
                    <div className={'card'}>
                        <div className={'icon'}><img alt={''} src={'./img/common/contact/1.png'} /></div>
                        <div className={'title'}>IR Enquiries</div>
                        <ClickToSelect onSelect={onSelect}><div className={'more'}>ir@signalplus.com<img alt={''} src={'./img/common/contact/icon.png'}/></div></ClickToSelect>
                    </div>
                </AnimateScroll>
                <AnimateScroll obo={2}>
                    <div className={'card'}>
                        <div className={'icon'}><img alt={''} src={'./img/common/contact/2.png'} /></div>
                        <div className={'title'}>Press Enquiries</div>
                        <ClickToSelect onSelect={onSelect}><div className={'more'}>contact@signalplus.com<img alt={''} src={'./img/common/contact/icon.png'}/></div></ClickToSelect>
                    </div>
                </AnimateScroll>


            </div>

            <AnimateScroll aniamteType={4}><div className={'bannerTextTitle'} style={{marginTop:120}}>Our Offices
                <LightAnimate w={164} h={12} x={686} y={52} />
            </div></AnimateScroll>
            <div className={'location'}>
                <AnimateScroll aniamteType={2} obo={0}>
                    <div className={'card'}>
                        <div className={'title'}>Hong Kong</div>
                        <div className={'locationImage'}><img alt={''} src={'./img/common/contact/l0.png'} /></div>
                        <div className={'locationInfo'}>
                            <div><img src={'./img/common/contact/location.png'} alt={''} /></div>
                            <p>Level 35, Two Pacific Place, 88 Queensway, Admiralty, Hong Kong</p>
                        </div>
                    </div>
                </AnimateScroll>
                <AnimateScroll aniamteType={3} obo={1}>
                    <div className={'card'}>
                        <div className={'title'}>Singapore</div>
                        <div className={'locationImage'}><img alt={''} src={'./img/common/contact/l1.png'} /></div>
                        <div className={'locationInfo'}>
                            <div><img src={'./img/common/contact/location.png'} alt={''} /></div>
                            <p>2 Fusionopolis Way, Innovis Tower, Singapore, 138634</p>
                        </div>
                    </div>
                </AnimateScroll>

            </div>
        </div>
    );
};

export default ContactusP;