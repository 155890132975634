import React, {useEffect, useRef} from 'react';
import gsap from "gsap";
import './breathLight.scss';
const BreathLight = ({text="",width=48,height=48,left=0,top=0,fontSize=16}) => {
    const breathLight=useRef();
    useEffect(()=>{
        let ctx=gsap.context(()=>{
            gsap.to(breathLight.current,{duration:.5,opacity:0.6,scale:0.8,repeat:-1,yoyo:true,ease:"none"});
        },breathLight);

        return ()=>ctx.kill();
    },[]);
    return (
        <div className={"breathLight"} style={{width:width,height:height,left:left,top:top}}>
            <span style={{fontSize:fontSize}}>{text}</span>
            <img ref={breathLight} src={'./img/breathLight.png'} alt={""} />
        </div>
    );
};

export default BreathLight;