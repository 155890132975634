import React from 'react';
import './app.scss'
import {BrowserView, MobileView} from "react-device-detect";
import AppP from "./appP";
import AppM from "./appM";
const App = () => {
    return (
        <>
            <BrowserView>
                <AppP/>
            </BrowserView>
            <MobileView>
                <AppM/>
            </MobileView>
        </>
    );
};

export default App;