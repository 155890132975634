import React from 'react'
import LightAnimate from '../../component/lightAnimate'
import useRem from '../../common/useRem'
import MCarouselType1 from '../../component/mobile/mCarouselType1/mCarouselType1'
import AnimateScroll from '../../component/animateScroll/animateScroll'
import useAlert from '../../component/mAlert/useAlert'

const DashboardM = () => {
  const xs = useRem()
  const { setAlert } = useAlert()
  return (
    <div className={'dashboardM'}>
      <AnimateScroll aniamteType={2}>
        <div className={'bannerTextInfoSmall'}>
          Leverage SignalPlus's Comprehensive Suite of <br />
          <span>
            <LightAnimate w={'100%'} h={xs * 6} x={0} y={'88%'} />
            Advanced Tools
          </span>{' '}
          to Empower Your Options Trading, No Matter Your Needs
        </div>
      </AnimateScroll>
      <AnimateScroll aniamteType={3}>
        <div className={'bannerTextField center'}>
          As seasoned risk-takers ourselves, we know what it takes to succeed in
          options trading, and we identified an obvious deficit in crypto
          offerings that could satisfy our own trading needs. That's what led us
          to build the SignalPlus Trading Dashboard - a modern, user-friendly
          platform that integrates all the necessary tools for even the most
          professional option trader.
        </div>
      </AnimateScroll>
      <AnimateScroll aniamteType={3}>
        <div className={'bannerTextField center'}>
          We were always taught that good things should be shared - Web3 and all
          - so{' '}
          <span>
            <a href={'https://t.signalplus.com/user/register'}>Register Now</a>
          </span>{' '}
          completely <span>free-of-charge</span> with no strings attached, and
          embark on your options journey with SignalPlus today!
        </div>
      </AnimateScroll>
      <AnimateScroll aniamteType={0}>
        <a
          className={'button dashboard'}
          href={'#/app'}
          style={{ width: xs * 230, marginTop: xs * 24 }}
        >
          <span style={{ lineHeight: '20px' }}>App Download</span>
          <svg
            style={{ marginBottom: '-8px', marginLeft: '3px' }}
            width='29'
            height='28'
            viewBox='0 0 29 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M14.5 26.25C7.74501 26.25 2.25 20.755 2.25 14C2.25 7.24501 7.74501 1.75 14.5 1.75C21.255 1.75 26.75 7.24501 26.75 14C26.75 20.755 21.255 26.25 14.5 26.25ZM14.5 3.5C8.71011 3.5 4 8.21011 4 14C4 19.7899 8.71011 24.5 14.5 24.5C20.2899 24.5 25 19.7899 25 14C25 8.21011 20.2899 3.5 14.5 3.5Z'
              fill='black'
            />
            <path
              d='M20.5418 13.7035C20.5409 13.7001 20.5366 13.6965 20.5348 13.6931C20.4928 13.5942 20.4351 13.4997 20.3546 13.4183C20.3537 13.4174 20.3528 13.4174 20.352 13.4166C20.3511 13.4157 20.3511 13.4148 20.3502 13.4139L16.005 9.05646C15.6637 8.71521 15.1107 8.71258 14.7677 9.05472C14.4256 9.39597 14.4247 9.94983 14.766 10.292L17.5914 13.1252H9.24998C8.76696 13.1252 8.375 13.5172 8.375 14.0003C8.375 14.4833 8.767 14.8753 9.24998 14.8753H17.6377L14.7362 17.7453C14.3924 18.0856 14.3897 18.6386 14.7292 18.9825C14.8998 19.1558 15.1256 19.2424 15.3513 19.2424C15.5736 19.2424 15.7958 19.1575 15.9665 18.9895L20.3485 14.6557C20.6066 14.3992 20.6722 14.0211 20.5418 13.7035Z'
              fill='black'
            />
          </svg>
        </a>
        <div
          className={'button dashboard'}
          onClick={() => {
            setAlert('123', 'https://t.signalplus.com/')
          }}
          style={{ width: xs * 230, marginTop: xs * 24 }}
        >
          Start for Free
        </div>
      </AnimateScroll>
      <AnimateScroll aniamteType={0}>
        <MCarouselType1
          imgwidth={Math.floor(xs * 288)}
          imageheight={Math.floor(xs * 140)}
        />
      </AnimateScroll>
      <div
        className={'tradingViewM'}
        style={{  marginTop: xs * 52 ,marginLeft: xs * 28,marginRight: xs * 28}}
      >
        <div className={'tradingViewM_title'}>TradingView Technology</div>
        <div className={'tradingViewM_text'}>
          SignalPlus uses TradingView technology to display prices on charts.
          Trading View is a charting platform for a global community of traders
          and investors. Supported by robust technologies across browsers,
          desktop and mobile apps, the platform provides unparalleled access to
          live data e.g. <a href={'https://www.tradingview.com/symbols/BTCUSD/'} style={{color: 'rgb(1, 243, 214)'}}>BTCUSD Chart</a>, the latest news, financial reports, stock
          screener and economic calendar.
        </div>
      </div>
    </div>
  )
}

export default DashboardM
