import React, {useEffect, useMemo, useRef, useState} from 'react';
import './mCarouselType1.scss';
import axios from 'axios';
import {CarouselDataaAnalyze} from '../../pc/carouselType1/data'
import useRem from "../../../common/useRem";
import ScrollBox from "../../scrollBox/scrollBox";

const MCarouselType2 = ({imgwidth=740,imageheight=100,style={}}) => {
    const banner=useRef();

    const [nowId, setNowId] = useState(0);
    const [nowSmallId, setNowSmallId] = useState(0);
    const xs=useRem();
    const smallIconWidth=98;
    const [dataArray,setDataArray]=useState(null);

    useEffect(()=>{
        axios.get("./config/video").then((data)=>{
            setDataArray(new CarouselDataaAnalyze(data.data));
        })
    },[]);

    const listContent=useMemo(()=>{
        if(!dataArray){
            return (<></>)
        }
        let ary=[];
        for(let i=0;i<dataArray.length();i++){
            let smallary=[];
            for(let j=0;j<dataArray.smallLength(i);j++){
                smallary.push(
                    <div className={'changeIconPar'} style={{width:Math.floor(xs*smallIconWidth)}} onClick={()=>clickToSmallChange(j)} key={j}>
                        <div style={{width:Math.floor(xs*smallIconWidth),height:Math.floor(xs*54)}} className={`changeIcon ${nowSmallId===j?"froce":""}`}>
                            <img src={'./img/common/youtubeplay.png'} alt={''} />
                            <img className={'tipimg'} src={dataArray.getSmallInfo(i,j).img} alt={''}/>
                        </div>
                        <p>{dataArray.getSmallInfo(i,j).text}</p>
                    </div>
                )
            }
            ary.push(
                <div key={i} className={"bg"} style={{width:Math.floor(xs*327)}}>
                    {/*<div className={"title"}>{dataArray.getTitle(i)}</div>*/}

                    <div className={"content"} style={{width:imgwidth+8,height:imageheight+8}}>
                        <div className={'tipcontent'} style={{width:imgwidth, height:imageheight}}>
                            <iframe width="100%" height="100%" src={dataArray.getSmallInfo(nowId,nowSmallId).url}
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen></iframe>
                        </div>
                    </div>

                    {/*<div className={'leftRightControl'}>
                        <div onClick={()=>{clickToSmallChange(nowSmallId-1)}} className={'leftButton'}><img src={`./img/common/${nowSmallId<=0?"left":"leftlight"}.png`} /></div>
                        <div onClick={()=>{clickToSmallChange(nowSmallId+1)}} className={'rightButton'}><img src={`./img/common/${nowSmallId>=(dataArray.smallLength(i)-1)?"right":"rightlight"}.png`} /></div>
                    </div>*/}
                    <ScrollBox mobile={true} widths={Math.floor(xs*327)}>
                        <div ref={banner} className={"infoChange"} style={{width:Math.floor(xs*smallIconWidth+8)*dataArray.smallLength(i)}}>
                            {smallary}
                        </div>
                    </ScrollBox>

                </div>
            )
        }
        return ary;
    },[nowId,nowSmallId,dataArray]);

    const clickToSmallChange=(id)=>{
        if(id>=0&&id<=(dataArray.smallLength(nowId)-1)){
            setNowSmallId(id);
        }
    }


    return (
        <div style={{...style}} className={'carouselType1 video'}>
            <div className={'listFlex'} style={{width:window.innerWidth*(dataArray?dataArray.length():1),minHeight:355}}>
                {listContent}
            </div>
        </div>
    );
};

export default MCarouselType2;