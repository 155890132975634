import React, {useEffect, useMemo, useRef} from 'react';
import './style.scss'
import {gsap} from "gsap";
import {CarouselDataA} from "./data";
import {supportsHEVCAlpha} from "../../../common/tool";

const PcCarousel = ({data=CarouselDataA,left=true,imgwidth=574}) => {
    const circleRef = useRef(null);
    const leftContent = useRef(null);
    const doAnimate=(id)=>{
        gsap.to(leftContent.current,{duration:.3,x:574*id*-1});
    }
    const getVideoURl=(url)=>{
        if(supportsHEVCAlpha()){
            return url+".mp4";
        }else{
            return url+".webm";
        }
    }
    const imgArray=useMemo(()=>{
        let ary=[];
        for(let i=0;i<data.length();i++){
            //<img alt={""} src={data.getImageURL(i)} />
            ary.push(
                <div key={i} className={"carouselDivimg"}>
                    <div className={'mask'}><img alt={''} src={'./img/pc/board.png'} /></div>
                    <video muted loop autoPlay={true} controls={false}>
                        <source src={getVideoURl(data.getVideoURL(i))}/>
                    </video>
                </div>
            )
        }
        return ary;
    },[]);

    const infoArray=useMemo(()=>{
        let ary=[];
        for(let i=0;i<data.length();i++){
            ary.push(
                <div key={i} className={'carouselDiv'}>
                    <div className={'tip'}>
                        {i+1}/{data.length()}<div className={'ellipse'}></div>
                    </div>
                    <div className={'title'}>{data.getTitle(i)}</div>
                    <div className={'content'}>
                        {new Array(data.smallLength(i)).fill("").map((obj,j)=>{
                            return(
                                <p key={j+"p"}><span className={'ellipse'}></span>{data.getSmallInfo(i,j).text}</p>
                            )
                        })}
                    </div>
                    <a href={data.getmoreURL(i)}><div className={'more'}>Learn More<img alt={''} src={'./img/pc/jiantou.png'}/></div></a>
                </div>
            )
        }
        return ary;
    },[]);

    useEffect(() => {
        // setTimeout(()=>{window.scrollTo(0, 0)},500);
        let ctx = gsap.context(() => {
            gsap.utils.toArray(".carouselDiv").forEach((item,i)=>{
                gsap.to(item,{
                    opacity:1,
                    duration:.5,
                    scrollTrigger:{
                        onLeave:()=>{gsap.set(item,{opacity:.3})},
                        onEnterBack:()=>{gsap.set(item,{opacity:1});doAnimate(i)},
                        onLeaveBack:()=>{gsap.set(item,{opacity:.3})},
                        onEnter:()=>{gsap.set(item,{opacity:1});doAnimate(i)},

                        trigger:item,
                        start:"50% 80%",
                        end:'50% 20%',
                        // markers:true,
                    }
                })
            })
        }, circleRef); // <- IMPORTANT! Scopes selector text
        return () => ctx.kill(); // cleanup
    }, []);
    return (
        <div ref={circleRef} className={`introContent ${left?"":"reverse"}`}>
            <div className={'left'}>
                <div ref={leftContent} className={'leftContent'} style={{width:imgwidth*data.length()}}>
                    {imgArray}
                </div>
            </div>
            <div className={'right'}>
                {infoArray}
            </div>
        </div>
    );
};

export default PcCarousel;