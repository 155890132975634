import useAlert from './useAlert';
import {isMobile} from "react-device-detect";

const AlertPopup = () => {
    const { text, type,setAlert } = useAlert();

    if (text==="123" && type) {
        return (
            <div style={{position:'fixed',
                display:"flex", justifyContent:"center",alignItems:"center",
                left:0,top:0,zIndex:999,backgroundColor:'rgba(0,0,0,.5)',width:window.innerWidth,height:window.innerHeight}}>
                <div style={{width:'80%',position:"relative"}}>
                    <img alt={''} src={'./img/alert.png'} />
                    <a style={{width:"40%",height:"30%", display:"block",
                        position:"absolute",left:'30%', bottom:"10%"}} href={type}></a>
                </div>
            </div>
        );
    }else if(text==="wechat" && type){
        return (
            <div className={'wechatAlert'} onClick={()=>{setAlert("","",null)}} style={{width:window.innerWidth,height:window.innerHeight}}>
                <img style={{width:isMobile?"80%":400}} alt={''} src={'./img/wechat.png'}/>
            </div>
        )
    }else {
        return <></>;
    }
};

export default AlertPopup;