export const appUrls = {
    iOSAppStore: 'https://apps.apple.com/sg/app/signalplus/id6466781845',
    iOSTestflight: 'https://testflight.apple.com/join/ez8dTROz',
    AndroidPlayStore: 'https://play.google.com/store/apps/details?id=com.signalplus.mobile',

    isAndroid: /android/i.test(navigator.userAgent), 
    isiOS: /(iPhone OS|iPad)/.test(navigator.userAgent),

    qrImgAddress_iOSAppStore: './img/common/app/qr-iosappstore.png',
    qrImgAddress_iOSTestflight: './img/common/app/qr-iostestflight.png',
    qrImgAddress_AndroidPlayStore: './img/common/app/qr-androidplay.png',
};
