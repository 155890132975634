/*
* 非视频数据结构
* {img:"",title:"",infoList:[{text:"",x:"",y:""}]}
* */

const carouselDataA=[
    {img:"./video/0.png", video:"./video/pnl",title:"Live Risk / PnL",
        url:"#/dashboard",infoList:[
        {text:"Real-time inventory, intraday risk exposure, and detailed PL breakdown by Greeks across expiries and strikes"},
        {text:"Advanced analytical tools covering PL attribution, strike analysis, theta analysis, and much more"}]
    },
    {img:"./video/1.png", video:"./video/volatility",title:"Volatility Lab",
        url:"#/dashboard",infoList:[
            {text:"Visualized volatility profile including Term Structure, Model Volatility Smile, Model Volatility Surface, 7D RV Momentum and more"}]
    },
    {img:"./video/2.png", video:"./video/smart",title:"Smart Dealing",
        url:"#/dashboard",infoList:[
            {text:"Execute multi-legged orders using the dashboard's embedded algorithms to power better executions while minimizing slippage"},
            {text:"Auto-calculate gamma- and vega-neutral offsets with a few simple clicks"}]
    },
    {img:"./video/3.png", video:"./video/risk",title:"Risk Scenario",
        url:"#/dashboard",infoList:[
            {text:"Powerful simulation tools and scenario analyses to stress-test your PL and exposures well ahead of time"}]
    },
    {img:"./video/4.png", video:"./video/dynamic",title:"Dynamic Delta Hedge (DDH)",
        url:"#/dashboard",infoList:[
            {text:"Industry-leading, highly customizable DDH robot to reliably automate your delta hedging needs across all market conditions"},
            {text:"Real-time trade notifications via Telegram confirmations"}
        ]
    },
    {img:"./video/5.png", video:"./video/highly",title:"Highly Customizable",
        url:"#/dashboard",infoList:[
            {text:"Fully customizable dashboard to fill all your trading needs"},
            {text:"Single workspace view to see all your inventory, order history, and real-time market data in one place"},
            {text:"Seamless and direct execution without having to switch window views"}]
    },
];

const carouselDataB=[
    {img:"./video/6.png", video:"./video/industry",title:"Industry-Leading Market Making Robot",
        url:"#/automation",infoList:[
            {text:"100% automated protocol with full hedging capabilities"},
            {text:"24/7 uptime trading across major venues with minimal human oversight"},
            {text:"200+ user-defined and bespoke parameters"}]
    },
    {img:"./video/7.png", video:"./video/amplify",title:"Amplify Your Market Making Capacity",
        url:"#/automation",infoList:[
            {text:"Our platform provides automated and fully-customizable market making services via API subscriptions across all supported exchanges"},
            {text:"Unlock the power of options market-making for institutions of all sizes with our advanced robot. Boost liquidity provision and seize opportunities for growth like never before."}]
    },
];

import React from 'react';

class CarouselDataaAnalyze {
    constructor(data) {
        this.data=data;
    }
    length=()=>{
        return this.data.length;
    }

    smallLength=(id)=>{
        return this.data[id].infoList.length;
    }
    getmoreURL=(id)=>{
        return this.data[id].url;
    }
    getImageURL=(id)=>{
        return this.data[id].img;
    }
    getVideoURL=(id)=>{
        return this.data[id].video;
    }

    getVideoURL=(id)=>{
        return this.data[id].video;
    }

    getTitle=(id)=>{
        return this.data[id].title;
    }

    getSmallInfo=(id,smallid)=>{
        return this.data[id].infoList[smallid];
    }
}

const CarouselDataA=new CarouselDataaAnalyze(carouselDataA);
const CarouselDataB=new CarouselDataaAnalyze(carouselDataB);

export {CarouselDataA,CarouselDataB};