import React, {useEffect, useMemo, useRef, useState} from 'react';
import './carouselType1.scss';
import gsap from "gsap";
import {CarouselDataaAnalyze} from "./data";
import axios from 'axios';
import ScrollBox from "../../scrollBox/scrollBox";

const CarouselType2 = ({imgwidth=740,style={}}) => {
    const [nowId, setNowId] = useState(0);
    const [nowSmallId, setNowSmallId] = useState(0);
    const [dataArray,setDataArray]=useState(null);

    useEffect(()=>{
        axios.get("./config/video").then((data)=>{
            setDataArray(new CarouselDataaAnalyze(data.data));
        })
    },[]);

    const smallNavList=useMemo(()=>{
        if(!dataArray){
            return <></>
        }
        let arya=[];
        for(let j=0;j<dataArray.length();j++){
            let ary=[]
            for(let i=0;i<dataArray.smallLength(j);i++){
                ary.push(
                    <div
                        key={i}
                        className={`videoIcon ${nowSmallId===i?"froce":""}`}>
                        <div
                            onMouseEnter={(e)=>overAnimate(e,i)}
                            onMouseLeave={(e)=>outAnimate(e,i)}
                            onMouseUp={()=>clickToSmallChange(i)}
                            style={{borderColor:`${nowSmallId===i?"#01F3D6":"#0A3DC4"}`}} className={"youtubeMask"}>
                            <img src={'./img/common/youtubeplay.png'} alt={''} />
                            <img className={'tipimg'} src={dataArray.getSmallInfo(nowId,i).img} alt={''}/>
                        </div>
                        <p>{dataArray.getSmallInfo(j,i).text}</p>
                    </div>
                )
            }
            if(dataArray.smallLength(nowId)<=5){
                arya.push(<div className={'infoChange'} key={`${j}i`}>{ary}</div>);
            }else{
                arya.push(<div className={'infoChange'} style={{width:dataArray.smallLength(nowId)*208}} key={`${j}i`}>{ary}</div>);
            }

        }
        if(dataArray.smallLength(nowId)>5){
            return (
                <ScrollBox widths={1170} mobile={false}>
                    {arya}
                </ScrollBox>
            )
        }else{
            return(
                <div className={"infoChangePar"} style={{minHeight:151}}>
                    {arya}
                </div>
            )
        }

    },[nowSmallId,dataArray]);

    const overAnimate=(e,id)=>{
        e.stopPropagation();
        e.preventDefault();
        if(id!==nowSmallId){
            gsap.to(e.currentTarget,{duration:.3,borderColor:"#01F3D6"});
        }
    };
    const outAnimate=(e,id)=>{
        e.stopPropagation();
        e.preventDefault();
        if(id!==nowSmallId){
            gsap.to(e.currentTarget,{duration:.3,borderColor:"#0A3DC4"});
        }
    };

    const imgcontent=useMemo(()=>{
        if(!dataArray){
            return <></>
        }
        return(
            <div className={'imgin'}>
                <iframe width="100%" height="100%" src={dataArray.getSmallInfo(nowId,nowSmallId).url}
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>
        )
    },[nowId,nowSmallId,dataArray]);

    const clickToSmallChange=(id)=>{
        if(id>=0&&id<=(dataArray.smallLength(nowId)-1)){
            setNowSmallId(id);
        }
    }
    return (
        <div style={{width:1170,...style}} className={'carouselType1 video'}>
            <div className={"bg"}>
                {/*<div className={"title"}>{dataArray.getTitle(nowId)}</div>*/}
                <div className={"content"}>
                    <div className={'tipcontentPar'}>
                        <div className={'tipcontent'}>
                            {imgcontent}
                        </div>
                    </div>
                </div>
                {dataArray&&(
                    <>
                        <div onClick={()=>{clickToSmallChange(nowSmallId-1)}} className={'leftButton'}><img src={`./img/common/${nowSmallId<=0?"left":"leftlight"}.png`} /></div>
                        <div onClick={()=>{clickToSmallChange(nowSmallId+1)}} className={'rightButton'}><img src={`./img/common/${nowSmallId>=(dataArray.smallLength(nowId)-1)?"right":"rightlight"}.png`} /></div>
                    </>
                )}
                {smallNavList}
            </div>

        </div>
    );
};

export default CarouselType2;