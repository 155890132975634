import React, { useMemo } from 'react';
import '../pc.scss'
import {NavLink, useLocation} from 'react-router-dom'
import AnimateButton from "../../animateButton/animateButton";
const PcNav = () => {
    const { hash } = location;
    const isInIFrame = useMemo(() => window.top !== window.self, []);
    return (
        <div className={`nav ${hash.replace(/^#\/+/, '').replace(/\/.*/, '').replace(/\W/g, '')} ${isInIFrame ? 'iframe' : ''}`}>
            <div className={'logo'}><img src={'./img/pc/logo.png'} /></div>
            <div className={'menu'}>
                <NavLink className={({ isActive }) => isActive ? "tab froce" : "tab"} to="/">Home</NavLink>
                <NavLink className={({ isActive }) => isActive ? "tab froce" : "tab"} to="/dashboard">Dashboard</NavLink>
                <NavLink className={({ isActive }) => isActive ? "tab froce" : "tab"} to="/automation">Automation</NavLink>
                <NavLink className={({ isActive }) => isActive ? "tab froce" : "tab"} to="/content">Content</NavLink>
                <NavLink className={({ isActive }) => isActive ? "tab froce" : "tab"} to="/company">Company</NavLink>
                <NavLink className={({ isActive }) => isActive ? "tab froce" : "tab"} to="/contactus">Contact Us</NavLink>
            </div>
            {/*<div className={'button login'} onClick={()=>{window.location.href="https://t.signalplus.com/"}}>Log In</div>*/}
            <AnimateButton classname={"login"} href={"https://t.signalplus.com/"} text={'Log In'}></AnimateButton>
        </div>
    );
};

export default PcNav;