import React from 'react';
import LightAnimate from "../../component/lightAnimate";
import useRem from "../../common/useRem";
import AnimateScroll from "../../component/animateScroll/animateScroll";

const CompanyM = () => {
    const xs=useRem();
    return (
        <div className={'companyM'}>
            <AnimateScroll aniamteType={2}><div className={'bannerTextInfoSmall'}>
                The <span><LightAnimate w={"100%"} h={xs*6} x={0} y={"88%"} />Team</span> Behind the Vision
            </div>></AnimateScroll>
            <AnimateScroll aniamteType={3}><div className={'bannerTextTitle'} style={{marginTop:Math.floor(35*xs)}}>Who is SignalPlus?
            </div></AnimateScroll>
            <AnimateScroll aniamteType={2}><div className={'bannerTextField center'}>
                Formed in 2021, SignalPlus is a technology company looking to develop an open, accessible technology platform to democratize and empower proficient crypto options trading for users of all levels. Founded by an exceptionally seasoned team of traders, technologists, builders, and repeat entrepreneurs, the team's vision is to create an enterprise software solution that would translate our domain expertise into practical, programmatic software services that can be easily leveraged by the crypto industry to accelerate its options market development in a professional manner.
            </div></AnimateScroll>
            <AnimateScroll aniamteType={4}><div className={'companyImage'}><img alt={''} src={'./img/common/company.png'} /></div></AnimateScroll>

            <AnimateScroll aniamteType={2}><div className={'bannerTextTitle'} style={{marginTop:Math.floor(xs*85)}}>Shaping The Future<br/><span><LightAnimate w={"100%"} h={xs*6} x={0} y={"88%"} />Together</span> (WAGMI)
            </div></AnimateScroll>
            <AnimateScroll aniamteType={3}><div className={'bannerTextField center'}>
                Are you fascinated by the rapid evolution of digital assets and the exciting potential of the options market? Do you wish to join a team of experienced professionals with a burning desire to build something truly ground-breaking?
                We are always on the look-out for enthusiastic talent with a passion for digital assets and the fortitude to be trailblazer in this exciting industry. Reach out and speak with members of our
                team to see if we can participate in this meaningful journey together.z
            </div></AnimateScroll>
            <AnimateScroll aniamteType={4}><div className={'button positions'} onClick={()=>{window.location.href="https://www.linkedin.com/company/signalplushk/?originalSubdomain=hk"}}>View Open Positions</div></AnimateScroll>
        </div>
    );
};

export default CompanyM;