import React, {useEffect, useRef} from 'react';
import './style/lightAnimate.scss'
import {gsap} from "gsap";
const LightAnimate = ({x,y,w,h}) => {
    const ref=useRef();
    useEffect(()=>{
        let ctx = gsap.context(() => {
            gsap.to(ref.current,{
                width:w,
                opacity:1,
                duration:.5,
                scrollTrigger:{
                    /*onLeave:()=>{
                        gsap.to(ref.current,{duration:.5,opacity:0,width:0});
                        console.log("onLeave")
                    },
                    onLeaveBack:()=>{
                        gsap.to(ref.current,{duration:.5,opacity:0,width:0});
                        console.log("onLeaveBack")
                    },
                    onEnterBack:()=>{
                        gsap.from(ref.current,{duration:.5,width:w,opacity:1});
                        console.log("onEnterBack")
                    },
                    onEnter:()=>{
                        gsap.from(ref.current,{duration:.5,width:w,opacity:1});
                        console.log("onEnter")
                    },*/
                    scrub:1.5,
                    trigger:ref.current,
                    start:"top 80%",
                    end:'top 50%',
                    // markers:true,
                }
            })
        }, ref); // <- IMPORTANT! Scopes selector text
        return () => ctx.kill(); // cleanup
    },[]);
    return (
        <div ref={ref} style={{width:0,height:h,left:x,top:y,opacity:0}} className={'lightAnimate'}></div>
    );
};

export default LightAnimate;