import React from 'react';
import './automation.scss'
import {BrowserView, MobileView} from "react-device-detect";
import AutomationP from "./automationP";
import AutomationM from "./automationM";
const Automation = () => {
    return (
        <>
            <BrowserView>
                <AutomationP/>
            </BrowserView>
            <MobileView>
                <AutomationM/>
            </MobileView>
        </>
    );
};

export default Automation;