import { useState, useEffect } from "react";
// Hook
function useWindowScroll() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowScroll, setWindowScroll] = useState({
        x: 0,
        y: 0,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            // const winScroll = document.body || document.documentElement;
            let winScroll= document.documentElement.scrollTop ? document.documentElement : document.body;
            setWindowScroll({
                x: winScroll.scrollLeft,
                y: winScroll.scrollTop,
            });
        }
        // Add event listener
        window.addEventListener("scroll", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowScroll;
}


function getScrollHeight(){
    let scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
    if(document.body){
        bodyScrollHeight = document.body.scrollHeight;
    }
    if(document.documentElement){
        documentScrollHeight = document.documentElement.scrollHeight;
    }
    scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight ;
    return scrollHeight;
}

function getWindowHeight(){
    let windowHeight = 0;
    if(document.compatMode == "CSS1Compat"){
        windowHeight = document.documentElement.clientHeight;
    }else{
        windowHeight = document.body.clientHeight;
    }
    return windowHeight;
}

export default useWindowScroll;
export {getScrollHeight,getWindowHeight}