import React, {useState} from 'react';
import './mFloor.scss';
import {isChinese} from "../../../common/tool";
import useAlert from "../../mAlert/useAlert";
const MFloor = () => {
    const { hash } = location;
    const {setAlert }=useAlert();
    const clickToShowWechat=()=>{
        setAlert("wechat","alert",null);
    }

    return (
        <div className={`floor ${hash.replace(/\W/g, '')}`}>
            <div className={'logo'}><img alt={''} src={'./img/common/floor/logo.png'} /></div>
            <div className={'tellMe'}>
                <div className={'icon'}><a href={'https://discord.com/invite/signalplus'}><img src={'./img/common/floor/0.png'} alt={''} /></a></div>
                <div className={'icon'}><a href={'https://t.me/SignalPlus_Playground'}><img src={'./img/common/floor/1.png'} alt={''} /></a></div>
                <div className={'icon'}><a href={'https://twitter.com/SignalPlus_Web3'}><img src={'./img/common/floor/2.png'} alt={''} /></a></div>
                <div className={'icon'} onClick={clickToShowWechat}><img src={'./img/common/floor/3.png'} alt={''} /></div>
                <div className={'icon'}><a href={'#/app'}><img src={'./img/common/floor/4.png'} alt={'Download App'} /></a></div>
            </div>
            <div className={'tellMe'} style={{display:'block'}}>
                <div className={'text'} onClick={()=>{setAlert("123","https://t.signalplus.com/")}}>Trading Dashboard</div>
                <div className={'text'}><a href={"https://static.signalplus.com/t/SignalPlus_PrivacyPolicy.pdf"}>Privacy Policy</a></div>
                <div className={'text'}><a href={"#/contactus"}>Contact Us</a></div>
                <div className={'text'}><a href={"https://static.signalplus.com/t/SignalPlus_CookiePolicy.pdf"}>Cookie Policy</a></div>
                <div className={'text'}><a href={isChinese()?"https://www.odaily.news/user/2147520128":"https://medium.com/@signalplus_web3"}>Read Our Commentary</a></div>
            </div>
        </div>
    );
};

export default MFloor;