import React, { useState } from 'react';
import AnimateScroll from "../../component/animateScroll/animateScroll";
import {appUrls} from './config';

const iosSvg = <svg width="28" height="36" viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.7801 25.9166C26.0617 25.0652 24.7655 23.7973 24.0084 22.0216C23.4635 20.7438 23.3291 19.4027 23.4779 18.0265C23.6161 16.7479 24.0638 15.5939 24.8423 14.5705C25.4259 13.8034 26.1481 13.1874 26.9513 12.6618C27.1471 12.5337 27.1484 12.5318 27.0078 12.3369C26.7251 11.945 26.4093 11.5825 26.0575 11.2502C24.5616 9.83752 22.7358 9.23045 20.7291 9.08469C19.6878 9.00912 18.6819 9.23011 17.701 9.55746C16.8749 9.8332 16.0504 10.1175 15.2399 10.4356C14.5492 10.7068 13.8901 10.7012 13.1923 10.4499C12.3674 10.1528 11.5561 9.81666 10.7173 9.5588C9.89277 9.30542 9.0598 9.11812 8.18484 9.18191C7.43846 9.23627 6.70997 9.36743 6.00821 9.62588C3.47829 10.5574 1.80548 12.3562 0.806917 14.8178C0.204718 16.3022 -0.0204418 17.8588 0.00320703 19.5387C-0.0163181 20.2933 0.0548766 21.1251 0.174114 21.954C0.491882 24.1626 1.17318 26.2585 2.12047 28.2733C2.83445 29.7919 3.77554 31.1651 4.80575 32.4807C5.3918 33.2291 6.04473 33.9128 6.82389 34.4665C7.78097 35.1465 8.82664 35.3467 9.96804 35.0587C10.5611 34.9091 11.1346 34.7053 11.6993 34.4732C12.5532 34.1222 13.4327 33.8715 14.3592 33.8194C15.4924 33.7556 16.5587 34.0369 17.5939 34.4665C18.142 34.6939 18.7003 34.8878 19.2791 35.0238C20.4496 35.2988 21.5384 35.1211 22.542 34.4516C23.1219 34.0648 23.6218 33.588 24.0684 33.0573C25.7638 31.0423 27.1124 28.8266 27.944 26.3143C28.035 26.0396 28.0375 26.0441 27.7801 25.9166Z" fill="white"/>
<path d="M14.1314 8.78208C15.0291 8.82068 15.8889 8.66781 16.7032 8.28078C17.7061 7.80413 18.5089 7.0823 19.1894 6.21996C20.2759 4.84331 20.9045 3.29476 20.9047 1.51986C20.9047 1.40366 20.9065 1.28745 20.9043 1.17134C20.8973 0.799867 20.8694 0.785161 20.4964 0.834248C19.6087 0.951051 18.7883 1.26256 18.0027 1.6781C16.1767 2.64403 14.9473 4.126 14.2539 6.0569C13.9582 6.88019 13.8621 7.73746 13.9435 8.61246C13.9556 8.74273 14.0152 8.77711 14.1314 8.78208Z" fill="white"/>
</svg>;

const qrSvg = <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_18048_70101)">
<path d="M5.44443 12.4439H0.000244141V15.5549H5.44443V12.4439ZM0.000244141 27.9989H10.8887V17.1104H0.000244141V27.9989ZM3.11123 20.2214H7.77771V24.8878H3.11123V20.2214ZM10.8887 0H0.000244141V10.8884H10.8887V0ZM7.77771 7.7775H3.11123V3.11099H7.77771V7.7775ZM20.2216 20.2214H23.3326V17.1104H17.1106V23.3324H20.2216V20.2214ZM22.5549 15.5549H27.9991V12.4439H22.5549V15.5549ZM24.8881 24.8879H17.1106V27.9989H27.9991V17.1104H24.8881V24.8879ZM17.1106 0V10.8884H27.9991V0H17.1106ZM24.8881 7.7775H20.2216V3.11099H24.8881V7.7775ZM12.4441 12.4439H8.55542V15.5549H12.4441V20.2214H15.5551V7.7775H12.4441V12.4439ZM12.4441 4.66651H15.5551V0H12.4441V4.66651ZM12.4441 27.9989H15.5551V23.3324H12.4441V27.9989Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_18048_70101">
<rect width="28" height="28" fill="white"/>
</clipPath>
</defs>
</svg>;

const androidSvg = <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_18048_70109)">
<path d="M1.42078 27.0824L14.5214 14.0126L1.42078 0.917656C1.09812 1.43839 0.875 2.28173 0.875 3.49661V24.5029C0.875 25.7179 1.09812 26.5612 1.42078 27.0824ZM20.0543 19.5423L15.7616 15.2276L3.00852 28H3.13288C4.22439 28 5.16726 27.6027 6.68062 26.7842L20.0543 19.5423ZM21.6664 9.32493L17.002 14.0126L21.6664 18.6751L24.4949 17.1371C25.5368 16.5664 27.125 15.5249 27.125 14.0126C27.125 12.4747 25.5368 11.4331 24.4949 10.8624L21.6664 9.32493ZM3.00852 0L15.7616 12.772L20.0543 8.45687L6.68062 1.21499C5.16726 0.396867 4.22445 0 3.13288 0H3.00852Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_18048_70109">
<rect width="28" height="28" fill="white"/>
</clipPath>
</defs>
</svg>;

const qrBoxSvg = <svg width="162" height="150" viewBox="0 0 162 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.5 74.134C0.833334 74.5189 0.833333 75.4811 1.5 75.866L13.5 82.7942C14.1667 83.1791 15 82.698 15 81.9282L15 68.0718C15 67.302 14.1667 66.8209 13.5 67.2058L1.5 74.134Z" fill="#CCCCCC"/>
<g filter="url(#filter0_b_4_114)">
<path d="M12 10C12 4.47715 16.4772 0 22 0H152C157.523 0 162 4.47715 162 10V140C162 145.523 157.523 150 152 150H22C16.4772 150 12 145.523 12 140V10Z" fill="#CCCCCC"/>
</g>
<defs>
<filter id="filter0_b_4_114" x="4" y="-8" width="166" height="166" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4_114"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4_114" result="shape"/>
</filter>
</defs>
</svg>;
const DashboardP = () => {
    const [hover, setHover] = useState([{left:0, top: 0},'']);
    return (<>
        <div className='appP bg' style={{backgroundImage: 'url(./img/common/app/bg.jpg)'}}></div>
        <div className='appP navbg'></div>
        <div className='appP phoneanimate'>
            <AnimateScroll aniamteType={6}><img className={'phone1'} src="./img/common/app/phone1.png" /></AnimateScroll>
            <AnimateScroll aniamteType={3}><img className={'phone2'} src="./img/common/app/phone2.png" /></AnimateScroll>
            <div className='shadow'></div>
        </div>
        <div className={'appP contentpc'}>
            <AnimateScroll aniamteType={2}><div className={'bannerTextInfo title'}>
                Download <span className='gradient-text'>SignalPlus</span> APP<br />
                Trade Anytime Anywhere<br />
                With Just a Tap
            </div></AnimateScroll>
            <div className='bar bannerTextInfo'>
                <span className='downloadsection ios'>iOS</span>
                <span className='downloadsection android'>Android</span>
                <a className='downloadbtn' href={appUrls.iOSAppStore} target='_blank' onMouseOver={() => setHover([{left: 280, top: 33}, appUrls.qrImgAddress_iOSAppStore])} onMouseOut={() => setHover('')}>
                    {iosSvg}
                    <small>Download from</small>
                    <b>App Store</b>
                    {qrSvg}
                </a>
                <a className='downloadbtn' href={appUrls.AndroidPlayStore} target='_blank' onMouseOver={() => setHover([{left: 590, top: 33}, appUrls.qrImgAddress_AndroidPlayStore])} onMouseOut={() => setHover('')}>
                    {androidSvg}
                    <small>Get it on</small>
                    <b>Google Play</b>
                    {qrSvg}
                </a>
                <a className='downloadbtn' href={appUrls.iOSTestflight} target='_blank' onMouseOver={() => setHover([{left: 280, top: 117}, appUrls.qrImgAddress_iOSTestflight])} onMouseOut={() => setHover('')}>
                    {iosSvg}
                    <small>Download</small>
                    <b>iOS Beta</b>
                    <span>TestFlight</span>
                    {qrSvg}
                </a>
                {hover[1] && <div id="qrBox" style={{left: hover[0].left + 'px', top: hover[0].top + 'px'}}>
                    {qrBoxSvg}
                    <div className='container'><img src={hover[1]} /></div>
                    <span>Scan to Download</span>
                </div>}
                
                <small className='hint'>If you cannot download, please contact us to get the official installation package: <a href="mailto:customersuccess@signalplus.com">customersuccess@signalplus.com</a></small>

                
            </div>
        </div>
        </>
    );
};

export default DashboardP;