import React from 'react';
import PcCarousel from "../../component/pc/pcCarousel/pcCarousel";
import LightAnimate from "../../component/lightAnimate";
import {CarouselDataB} from "../../component/pc/pcCarousel/data";
import PaoMaDeng from "../../component/pc/paoMaDeng/paoMaDeng";
import AnimateScroll from "../../component/animateScroll/animateScroll";
import {isChinese} from "../../common/tool";

const IndexP = () => {
    return (
        <div className={'contentpc'}>
            <div className={'banner'}>
                <AnimateScroll aniamteType={2}><div className={'textinfo'}>Democratizing Options for Digital Assets</div></AnimateScroll>
                <div className={'macbook'}><img src={'./img/pc/macbook.png'} alt={""} /></div>
            </div>
            <div className={'intro'}>
                <AnimateScroll aniamteType={2}><div className={'left'}>Industry-leading Options Trading All-in-One Dashboard
                    <LightAnimate w={220} h={12} x={172} y={107} />
                </div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'right'}>SignalPlus has produced a best-in-class dashboard with comprehensive crypto options pricing, analytics, liquidity aggregation and multi-vendor connectivity that satisfies the needs of professional traders with an intuitive and user-friendly interface – all free of charge.</div></AnimateScroll>
            </div>
            <PcCarousel></PcCarousel>
            <div className={'intro center'}>
                <AnimateScroll aniamteType={2}><div className={'center'}>Advanced Automation Tools
                    <LightAnimate w={639} h={12} x={-20} y={52} />
                </div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'right'} style={{width:972}}>
                    Take advantage of our comprehensive, battle-tested, Al-powered trading robot to fully automate your crypto options market making and delta hedging needs reliably regardless of market conditions.
                </div></AnimateScroll>
            </div>
            <PcCarousel data={CarouselDataB} left={false}></PcCarousel>
            <div className={'intro center'}>
                <AnimateScroll aniamteType={2}><div className={'center'}>Structured Product<br/>Pricer & Risk Management Engine
                    <LightAnimate w={730} h={12} x={0} y={113} />
                </div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'right'} style={{width:972}}>
                    SignalPlus comprehensive structured product solution provides issuers with an effective and efficient way to manage risk through a fully automated hedging protocol. With limited human oversight required, issuers can rely on the wall street-grade pricer and in-house risk management engine to ensure the best risk transfer pricing, while the transparent model maximizes margin capture. The ability to support bespoke product design also allows issuers to tailor solutions to specific needs, further increasing efficiency. By utilizing this solution, structured product issuers can benefit from streamlined processes, reduced manual work, and access to the best risk models in the market, ultimately leading to improved performance and profitability.
                </div></AnimateScroll>
            </div>
            <div className={'manageEngine'}>
                <AnimateScroll aniamteType={4}><div className={"tip"}>
                    <div className={'icon'}><img alt={""} src={'./img/icon/0.png'} /></div>
                    <div className={'text'}>In-house risk management engine ensures best risk transfer pricing</div>
                </div></AnimateScroll>
                <AnimateScroll aniamteType={4}><div className={"tip"}>
                    <div className={'icon'}><img alt={""} src={'./img/icon/1.png'} /></div>
                    <div className={'text'}>Wall Street- grade pricer, expandable to all popular TradFi structured products</div>
                </div></AnimateScroll>
                <AnimateScroll aniamteType={4}><div className={"tip"}>
                    <div className={'icon'}><img alt={""} src={'./img/icon/2.png'} /></div>
                    <div className={'text'}>Full model & pricing transparency that ensure maximum margin capture</div>
                </div></AnimateScroll>
                <AnimateScroll aniamteType={4}><div className={"tip"}>
                    <div className={'icon'}><img alt={""} src={'./img/icon/3.png'} /></div>
                    <div className={'text'}>Bespoke product design and terms tailored to particular needs</div>
                </div></AnimateScroll>
            </div>
            <div className={'intro center'}>
                <AnimateScroll aniamteType={2}><div className={'center'}>Trusted by Industry Leaders
                    <LightAnimate w={176} h={12} x={-3} y={52} />
                </div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'right'} style={{width:972}}>
                    SignalPlus is proud to be trusted by industry leaders and reputable digital assets firms as their partner of choice for crypto options. Our partnerships are built on trust, reliability, competence, and a shared commitment to innovation and long-term vision in the growth of this market. We maintain close and constant dialogue with our partners to ensure that our solution is meeting their ever expanding needs to provide the best options trading experience that industry has to offer. Please reach out to us and join our growing list of partners to build out our next steps together.
                </div></AnimateScroll>
            </div>
            <PaoMaDeng/>
            <div className={'intro center'}>
                <AnimateScroll aniamteType={2}><div className={'center'}>SignalPlus at a Glance
                    <LightAnimate w={249} h={12} x={238} y={52} />
                </div></AnimateScroll>
            </div>
            <div className={"glance"}>
                <AnimateScroll aniamteType={0} obo={0}><div className={'glanceBox'}>
                    <div className={"icon"}><img alt={""} src={'./img/icon/12.png'} /></div>
                    <p>SignalPlus' Dashboard offers a comprehensive suite of professional-grade pricing, analytics, and cross-exchange trading tools to satisfy even the most discerning traders.</p>
                </div></AnimateScroll>
                <AnimateScroll aniamteType={0} obo={1}><div className={'glanceBox'}>
                    <div className={"icon"}><img alt={""} src={'./img/icon/10.png'} /></div>
                    <p>A team composed of experts with diverse backgrounds from investment banks, technology leaders, and venture builders providing unparalleled support and insights to help our clients succeed</p>
                </div></AnimateScroll>
                <AnimateScroll aniamteType={0} obo={2}><div className={'glanceBox'}>
                    <div className={"icon"}><img alt={""} src={'./img/icon/11.png'} /></div>
                    <p>Financial institutions, crypto-native funds, and options traders rely on our trusted platform to execute options strategies and manage risk in digital assets with ease.</p>
                </div></AnimateScroll>
            </div>

            <div className={'intro center'}>
                <AnimateScroll aniamteType={2}><div className={'center'}>Latest News & Insights
                    <LightAnimate w={356} h={12} x={145} y={55} />
                </div></AnimateScroll>
            </div>
            <div className={"latestNews"}>
                <AnimateScroll aniamteType={2}><div className={'latestNewsBox'}>
                    <div className={"icon"}><img alt={''} src={'./img/icon/20.png'} /></div>
                    <p>Discover our University</p>
                    <a href={"#/content"}><div className={'more'}>Learn More<img alt={''} src={'./img/pc/jiantou.png'}/></div></a>
                </div></AnimateScroll>
                {/*<AnimateScroll aniamteType={3}><div className={'latestNewsBox'}>
                    <div className={"icon"}><img alt={''} src={'./img/icon/21.png'} /></div>
                    <p>What's New</p>
                    <a href={isChinese()?"https://www.odaily.news/user/2147520128":"https://medium.com/@signalplus_web3"}><div className={'more'}>Learn More<img alt={''} src={'./img/pc/jiantou.png'}/></div></a>
                </div></AnimateScroll>*/}
            </div>
        </div>
    );
};

export default IndexP;