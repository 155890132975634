import React from 'react';
import {createBrowserRouter,createHashRouter} from "react-router-dom";
import Navigation from "../component/navigation";
import Index from "../page/index";
import Dashboard from "../page/dashboard/dashboard";
import Automation from "../page/automation/automation";
import Content from "../page/content/content";
import Company from "../page/company/company";
import Contactus from "../page/contactus/contactus";
import App from '../page/app/app';
import News from '../page/news/news';
// console.log(10.1+0.2)
const RouterConfig = createHashRouter([
    {
        path: "/",
        element: <Navigation />,
        // loader: rootLoader, async 数据模拟
        children: [
            {
                path: "/",
                element: <Index />,
            },
            {
                path: "/dashboard",
                element: <Dashboard />,
            },
            {
                path: "/automation",
                element: <Automation />,
            },
            {
                path: "/content",
                element: <Content />,
            },
            {
                path: "/company",
                element: <Company />,
            },
            {
                path: "/contactus",
                element: <Contactus />,
            },
            {
                path: "/app",
                element: <App />,
            },
            {
                path: "/news/:id",
                element: <News />,
            }
        ],
    },
]);

export default RouterConfig;