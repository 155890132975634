import React, {useEffect, useMemo, useRef, useState} from 'react';
import './style.scss'
import useWindowSize from "../../../common/useWindowSize";
import gsap from 'gsap';
import useWindowScroll, {getScrollHeight, getWindowHeight} from "../../../common/useWindowScroll";


const rangdata=[
    {x:639, y:149, r:500, c:'0, 110, 167',alpha:1},
    {x:-100, y:346, r:500, c:'1, 230, 244',alpha:1},
    {x:-500, y:378, r:400, c:'0, 0, 0',alpha:1},
    {x:-514, y:294, r:400, c:'173, 255, 135',alpha:1},
    {x:717, y:263, r:450, c:'137, 218, 255',alpha:1},
    {x:1015, y:421, r:300, c:'42, 222, 208',alpha:1},
    {x:-180, y:646, r:135, c:'0, 41, 255',alpha:1},
    {x:-120, y:580, r:160, c:'0, 205, 217',alpha:1},
    {x:508, y:307, r:370, c:'255, 216, 115',alpha:1},
    {x:625, y:404, r:370, c:'255, 216, 115',alpha:.4},
]
const CanvasBg = ({height=800,topshow=true}) => {
    const heightxs=height/800;

    const size={width:screen.width};
    const [className, setClassName] = useState("");
    const canvas=useRef();
    const parClass=useRef();
    const windowscroll=useWindowScroll();
    const baseW=1707;
    const tl=useRef();
    const circle=(ctx,xx, yy, r, c,alpha=1)=>{
        const x=xx+(size.width-baseW)/4;//算上半径/4
        const y=yy*heightxs;
        // ctx.save();
        ctx.beginPath();
        let ypos=Math.floor((y+r)*1.3);
        let rxs=r*1.8;
        let rad = ctx.createRadialGradient(x+r, ypos, 0, x+r, ypos, rxs);
        rad.addColorStop(0, 'rgba('+c+','+alpha+')');
        rad.addColorStop(1, 'rgba('+c+',0)');
        ctx.fillStyle = rad;
        // ctx.filter = 'blur(280px)';
        // ctx.fillStyle = 'rgba('+c+',1)';

        // ctx.scale(1,.76);//椭圆
        ctx.arc(x+r, ypos, rxs, 0, Math.PI*2, false);
        // ctx.ellipse(x, y, r, r*0.8,0,Math.PI*2,false);
        ctx.fill();
        // ctx.setTransform(1, 0, 0, 1, 0, 0);//end 后恢复//椭圆

        // ctx.restore();

    }
    const drawMask=(ctx)=>{
        ctx.save();
        ctx.globalCompositeOperation = 'destination-out';
        ctx.fillStyle = "#FF0000";
        ctx.rotate(-3*Math.PI/180);
        ctx.fillRect(-200, height, size.width*1.5, height);
        ctx.setTransform(1, 0, 0, 1, 0, 0);//end 后恢复
        ctx.restore();
    }

    useEffect(()=>{
        let ctx = canvas.current.getContext("2d");
        ctx.canvas.width  = size.width;
        ctx.canvas.height = height;
        ctx.clearRect(0, 0, size.width, height);
        // ctx.globalCompositeOperation="source-over";

        tl.current=gsap.timeline({repeat: -1,onUpdate:()=>{
            drawMask(ctx)}
        });
        rangdata.forEach((item,i)=>{
            tl.current.add(gsap.to(item,{duration:4,ease:"none",yoyo:true,repeat:1,x:"+=random(-200, 200)",y:"+=random(-100, 200)",onUpdate:function (){
                let value=this.targets()[0];
                if(i===0){
                    ctx.clearRect(0, 0, size.width, height);
                }
                circle(ctx,
                    Math.floor(value.x),
                    Math.floor(value.y),
                    value.r,
                    value.c,
                    value.alpha);
            }}),"<")
        });
        tl.current.play();
        /*rangdata.forEach((item,i)=>{
            gsap.to(item,{duration:"random(2, 5)",ease:"none",yoyo:true,repeat:-1,x:"+=random(-50, 200)",y:"+=random(-30, 200)",onUpdate:function (){
                    if(i===0){
                        ctx.clearRect(0, 0, size.width, height);
                    }
                    let value=this.targets()[0];
                    circle(ctx,
                        Math.round(value.x),
                        Math.round(value.y),
                        value.r,
                        value.c,
                        value.alpha);
                    if(i===rangdata.length-1){
                        drawMask(ctx);
                    }
                }})
        })*/
        return()=>{
            /*rangdata.forEach((item,i)=>{
                gsap.killTweensOf(item);
            });*/
            tl.current.kill();
        }

    },[]);

    useEffect(()=>{
        if(!topshow){
            setClassName("bottom");
            if(windowscroll.y>=(getScrollHeight()-getWindowHeight()*2)){
                setClassName("bottom");
            }else{
                setClassName("none");
            }
            return ()=>{};
        }
        if(windowscroll.y<=height){
            setClassName("");
        }else if(windowscroll.y>=(getScrollHeight()-getWindowHeight()*2)){
            setClassName("bottom");
        }else{
            setClassName("none");
        }
    },[windowscroll,topshow]);
    useEffect(()=>{
        if(className==="none"){
            tl.current.pause();
        }else{
            tl.current.play();
        }
    },[className]);

    return (
        <div ref={parClass} className={`canvasBg ${className}`}>
            <canvas ref={canvas}></canvas>
        </div>
    );
};

export default CanvasBg;