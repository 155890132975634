import React, {useEffect, useMemo, useRef} from 'react';
import './mCarousel.scss'
import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {CarouselDataA} from "../../pc/pcCarousel/data";
import useRem from "../../../common/useRem";
import AnimateScroll from "../../animateScroll/animateScroll";

const MCarousel = ({data=CarouselDataA,left=true,imgwidth=574}) => {
    const circleRef = useRef(null);
    const leftContent = useRef(null);
    const xs=useRem();

    const imgArray=useMemo(()=>{
        let ary=[];
        for(let i=0;i<data.length();i++){
            //<img alt={""} src={data.getImageURL(i)} />
            ary.push(
                <div key={i} className={'carouselDivimgPar'} style={{minHeight:window.innerHeight}}>
                    <AnimateScroll aniamteType={4}><div className={"carouselDivimg"} style={{height:Math.floor(301*xs)}}>
                        <img alt={""} src={data.getImageURL(i)} />
                    </div></AnimateScroll>
                    <div className={'carouselDiv'} style={{width:Math.floor(imgwidth*xs)}}>
                        <AnimateScroll aniamteType={3}><div className={'tip'}>
                            {i+1}/{data.length()}<div className={'ellipse'}></div>
                        </div></AnimateScroll>
                        <AnimateScroll aniamteType={3}><div className={'title'}>{data.getTitle(i)}</div></AnimateScroll>
                        <div className={'content'}>
                            {new Array(data.smallLength(i)).fill("").map((obj,j)=>{
                                return(
                                    <AnimateScroll key={j+"p"} aniamteType={3}><p><span className={'ellipse'}></span>{data.getSmallInfo(i,j).text}</p></AnimateScroll>
                                )
                            })}
                        </div>
                        <AnimateScroll aniamteType={2}>
                            <a href={data.getmoreURL(i)}><div className={'more'}>Learn More<img alt={''} src={'./img/pc/jiantou.png'}/></div></a>
                        </AnimateScroll>
                    </div>
                </div>
            )
        }
        return ary;
    },[]);

    useEffect(() => {
        let ctx = gsap.context(() => {
            /*let sel=gsap.utils.toArray('.carouselDivimgPar');
            let tl=gsap.timeline();
            sel.forEach((item,i)=>{
                tl.set(item,{yPercent:50,opacity:0,scale:.7});
                tl.to(item,{yPercent:0,duration:6,opacity:1,scale:1,ease:"none"});
                tl.to(item,{yPercent:-50,duration:6,opacity:0,scale:.7,delay:14,ease:"none"});
            });
            ScrollTrigger.create({
                animation:tl,
                pin:true,
                scrub:1,
                start:'top top',
                end:`+=${(sel.length-1)*1000}px`,
                trigger:'.leftContent',
                anticipatePin:1,
            });*/
        }, circleRef); // <- IMPORTANT! Scopes selector text
        return () => ctx.kill(); // cleanup
    }, []);
    return (
        <div ref={circleRef} className={`introContent`}>
            <div className={'left'}>
                <div ref={leftContent} className={'leftContent'}>
                    {imgArray}
                </div>
            </div>
        </div>
    );
};

export default MCarousel;