import React, { useEffect, useMemo } from 'react';
import {isMobile} from "react-device-detect";
import {useParams} from "react-router-dom";

import './news.scss'
const News = () => {
    const {id} = useParams();
    const targetHost = useMemo(() => 
        /signalplus\.net/i.test(location.hostname) ? 'https://t.signalplus.net' :
        'https://t.signalplus.com', []);
    useEffect(() => {
        if (id) {
            if (!isMobile) {
                location.href = `${targetHost}/news?eventId=${encodeURIComponent(id)}&prePathname=%2Fdashboard`;
            } else {
                location.href = `${targetHost}/webview/news.html#id=${encodeURIComponent(id)}`;
            }
        }
    }, [id, targetHost])
    return (
        <>
        </>
    );
};

export default News;