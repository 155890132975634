import React from 'react';
import LightAnimate from "../../component/lightAnimate";
import AnimateScroll from "../../component/animateScroll/animateScroll";
import AnimateButton from "../../component/animateButton/animateButton";

const CompanyP = () => {
    return (
        <div className={'contentpc companyP'}>
            <AnimateScroll aniamteType={2}><div className={'bannerTextInfo'}>
                The Team Behind the Vision
                <LightAnimate w={167} h={12} x={125} y={68} />
            </div></AnimateScroll>
            <AnimateScroll aniamteType={3}><div className={'bannerTextTitle'} style={{marginTop:80}}>Who is SignalPlus?
            </div></AnimateScroll>
            <AnimateScroll aniamteType={2}><div className={'bannerTextField'} style={{marginTop:32}}>
                Formed in 2021, SignalPlus is a technology company looking to develop an open, accessible technology<br/> platform to democratize and empower proficient crypto options trading for users of all levels. Founded by an<br/> exceptionally seasoned team of traders, technologists, builders, and repeat entrepreneurs, the team's vision is<br/> to create an enterprise software solution that would translate our domain expertise into practical,<br/> programmatic software services that can be easily leveraged by the crypto industry to accelerate its options<br/> market development in a professional manner.
            </div></AnimateScroll>
            <AnimateScroll aniamteType={4}><div className={'companyImage'}><img alt={''} src={'./img/common/company.png'} /></div></AnimateScroll>

            <AnimateScroll aniamteType={2}><div className={'bannerTextTitle'} style={{marginTop:80}}>Shaping The Future Together (WAGMI)
                <LightAnimate w={199} h={12} x={729} y={56} />
            </div></AnimateScroll>
            <AnimateScroll aniamteType={3}><div className={'bannerTextField'}>
                Are you fascinated by the rapid evolution of digital assets and the exciting potential of the options market? Do you<br/> wish to join a team of experienced professionals with a burning desire to build something truly ground-breaking?<br/>
                We are always on the look-out for enthusiastic talent with a passion for digital assets and the fortitude to be<br/> trailblazer in this exciting industry. Reach out and speak with members of our<br/>
                team to see if we can participate in this meaningful journey together.z
            </div></AnimateScroll>
            <AnimateScroll aniamteType={0}>
                <AnimateButton classname={"positions"} href={"https://www.linkedin.com/company/signalplushk/?originalSubdomain=hk"} text={'View Open Positions'}/>
                {/*<div className={'button positions'}>View Open Positions</div>*/}
            </AnimateScroll>
        </div>
    );
};

export default CompanyP;