import React from 'react';
import {BrowserView, MobileView} from "react-device-detect";
import IndexP from "./indexP";
import IndexM from "./indexM";
import './index.scss';
const Index = () => {
    return (
        <>
            <BrowserView>
                <IndexP/>
            </BrowserView>
            <MobileView>
                <IndexM/>
            </MobileView>
        </>
    );
};

export default Index;