import React, {useEffect, useRef} from 'react';
import './paoMaDeng.scss';
import {gsap} from "gsap";
const PaoMaDeng = ({width=(13024/2-1440)}) => {
    const ref=useRef();
    useEffect(()=>{
        let ctx = gsap.context(() => {
            /*gsap.to("img",{
                x:width*-1,
                duration:1,
                scrollTrigger:{
                    scrub:1,
                    trigger:".filling",
                    start:"top 80%",
                    end:'bottom 20%',
                    // markers:true,
                }
            })*/
            gsap.to("img",{
                x:width*-1,
                yoyo:true,
                repeat:-1,
                duration:30,
                ease:"none",
            })
        }, ref); // <- IMPORTANT! Scopes selector text
        return () => ctx.kill(); // cleanup
    },[]);
    return (
        <div ref={ref}>
            <div className={"pmd"}>
                <img src={'./img/common/trustLogo.png'} alt={""} />
            </div>
        </div>
    );
};

export default PaoMaDeng;