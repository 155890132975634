import React, {useEffect, useRef, useState} from 'react';
import '../mobile.scss';
import useSize375 from "../../../common/useRem";
import {gsap} from "gsap";
import {NavLink} from "react-router-dom";
import useWindowScroll from "../../../common/useWindowScroll";
import useAlert from "../../mAlert/useAlert";

const MobileNav = () => {
    const { hash } = location;
    const xs=useSize375();
    const [isOpen, setIsOpen] = useState(false);
    const contentRef=useRef();
    const iconRef=useRef();
    const scroll=useWindowScroll();
    const [bgcol, setBgcol] = useState(false);
    const {setAlert }=useAlert();
    const clickControl=()=>{
        setIsOpen(!isOpen);
    };
    useEffect(()=>{
        if(!isOpen){
            gsap.to(contentRef.current,{duration:.5,height:xs*88,opacity:0})
            gsap.to(iconRef.current,{duration:.5,opacity:0})
        }else{
            gsap.to(contentRef.current,{duration:.5,height:window.innerHeight,opacity:1})
            gsap.to(iconRef.current,{duration:.5,opacity:1})
        }

    },[isOpen]);

    useEffect(()=>{
        if(scroll.y>window.innerHeight){
            setBgcol(true);
        }else{
            setBgcol(false);
        }
    },[scroll]);

    return (
        <div className={`nav ${bgcol?'bgcol':''} ${hash.replace(/^#\/+/, '').replace(/\/.*/, '').replace(/\W/g, '')}`} style={{height:xs*88}}>
            <div className={'navBox'} ref={contentRef} style={{height:xs*88,opacity:0}}>
                <div className={'content'} style={{paddingTop:xs*110}}>
                    <NavLink onClick={()=>{setIsOpen(false)}} className={({ isActive }) => isActive ? "tab froce" : "tab"} to="/">Home</NavLink>
                    <NavLink onClick={()=>{setIsOpen(false)}} className={({ isActive }) => isActive ? "tab froce" : "tab"} to="/dashboard">Dashboard</NavLink>
                    <NavLink onClick={()=>{setIsOpen(false)}} className={({ isActive }) => isActive ? "tab froce" : "tab"} to="/automation">Automation</NavLink>
                    <NavLink onClick={()=>{setIsOpen(false)}} className={({ isActive }) => isActive ? "tab froce" : "tab"} to="/content">Content</NavLink>
                    <NavLink onClick={()=>{setIsOpen(false)}} className={({ isActive }) => isActive ? "tab froce" : "tab"} to="/company">Company</NavLink>
                    <NavLink onClick={()=>{setIsOpen(false)}} className={({ isActive }) => isActive ? "tab froce" : "tab"} to="/contactus">Contact Us</NavLink>
                    <div className={'lineBox'}>
                        <div className={'login button'}
                             onClick={()=>{setAlert("123","https://t.signalplus.com/")}}
                             style={{width:xs*125}}>Log In</div>
                    </div>
                </div>
            </div>
            <div className={'navTop'}>
                <div className={"logo"} style={{width:xs*108}}><img src={'./img/pc/logo.png'} alt={''} /></div>
                <div className={"navControl"} onClick={clickControl} style={{width:xs*40,backgroundImage:'url("./img/common/menu.png")'}}>
                    <img ref={iconRef} style={{opacity:0}} src={'./img/common/close.png'} alt={''} /></div>
            </div>
        </div>
    );
};

export default MobileNav;