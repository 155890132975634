import React from 'react';
import './dashboard.scss'
import {BrowserView, MobileView} from "react-device-detect";
import DashboardP from "./dashboardP";
import DashboardM from "./dashboardM";
const Dashboard = () => {
    return (
        <>
            <BrowserView>
                <DashboardP/>
            </BrowserView>
            <MobileView>
                <DashboardM/>
            </MobileView>
        </>
    );
};

export default Dashboard;