import React, {useEffect, useMemo, useRef, useState} from 'react';
import './carouselType1.scss';
import BreathLight from "../../breathLight/breathLight";
import {CarouselData} from './data'
import gsap from "gsap";
import usePrevious from "../../../common/usePrevious";

const CarouselType1 = ({imgwidth=740,dataArray=CarouselData,style={}}) => {
    const banner=useRef();
    const tip=useRef();
    const navAnimate=useRef();
    const [nowId, setNowId] = useState(0);
    const [nowSmallId, setNowSmallId] = useState(0);
    const oldVal=usePrevious({nowId,nowSmallId});

    const smallNavList=useMemo(()=>{
        let ary=[];
        for(let i=0;i<dataArray.smallLength(nowId);i++){
            ary.push(
                <div
                    onMouseEnter={(e)=>overAnimate(e,i)}
                    onMouseLeave={(e)=>outAnimate(e,i)}
                    onClick={()=>clickToSmallChange(i)} key={i}
                    style={{opacity:`${nowSmallId===i?1:.4}`}}
                    className={`${dataArray.getSmallInfo(nowId,i).text.length>90?"changeIcon big":"changeIcon"} ${nowSmallId===i?"froce":""}`}>
                    <div className={'id'}>{i+1}</div>
                    <p>{dataArray.getSmallInfo(nowId,i).text}</p>
                </div>
            )
        }
        return(
            <div ref={navAnimate} className={"infoChange"}>
                {ary}
            </div>
        )
    },[nowId,nowSmallId]);

    const overAnimate=(e,id)=>{
        e.stopPropagation();
        e.preventDefault();
        if(id!==nowSmallId){
            gsap.to(e.currentTarget,{duration:.3,opacity:.8});
        }
    };
    const outAnimate=(e,id)=>{
        e.stopPropagation();
        e.preventDefault();
        if(id!==nowSmallId){
            gsap.to(e.currentTarget,{duration:.3,opacity:.4});
        }
    };

    const navList=useMemo(()=>{
        let ary=[];
        for(let i=0;i<dataArray.length();i++){
            ary.push(
                <div key={i} onClick={()=>clickToBigChange(i)} className={`icon ${nowId===i?"froce":""}`}>
                    {nowId===i&&dataArray.getTitle(nowId)}
                </div>
            )
        }
        return(
            <div className={'contentChange'}>
                {ary}
            </div>
        )
    },[nowId]);
    const imgcontent=useMemo(()=>{
        let ary=[];
        for(let i=0;i<dataArray.length();i++){
            ary.push(
                <img style={{width:imgwidth}} key={i} alt={''} src={`${dataArray.getImageURL(i)}`} />
            )
        }
        return(
            <div ref={banner} style={{width:imgwidth*dataArray.length()}} className={'imgin'}>{ary}</div>
        )
    },[]);

    useEffect(()=>{
        let ctx=gsap.context(()=>{
            if(oldVal.nowId !== nowId) {
                gsap.to(banner.current,{duration:.5,x:nowId*imgwidth*-1});
            }
        },banner);
        return ()=>ctx.kill();
    },[nowId]);

    const clickToSmallChange=(id)=>{
        if(id>=0&&id<=(dataArray.smallLength(nowId)-1)){
            setNowSmallId(id);
        }
    }
    const clickToBigChange=(id)=>{
        if(id===nowId){
            return;
        }
        if(id>=0&&id<=(dataArray.length()-1)){
            gsap.set(tip.current,{display:"none"});
            gsap.to(navAnimate.current,{duration:.3,opacity:0,y:"80%",onComplete:()=>{
                setNowId(id);
                setNowSmallId(0);

                gsap.to(navAnimate.current,{duration:.3,opacity:1,y:0,onComplete:()=>{gsap.set(tip.current,{display:"block",delay:.5});}});
            }});
        }
    }
    return (
        <div style={{width:1170,...style}} className={'carouselType1'}>
            <div className={"bg"}>
                <div className={"title"}>{dataArray.getTitle(nowId)}</div>
                <div className={"content"}>
                    <div className={'tipcontentPar'}>
                        <div className={'tipcontent'}>
                            {imgcontent}
                        </div>
                    </div>
                    <div ref={tip}>
                        {dataArray.getSmallInfo(nowId,nowSmallId).pos.map((item,i)=>{
                            return(
                                <div key={i} style={{left:item.x, top:item.y}} className={"tipAnimate"}>
                                    <BreathLight text={nowSmallId+1}/>
                                </div>
                            )
                        })}
                    </div>

                </div>
                <div onClick={()=>{clickToBigChange(nowId-1)}} className={'leftButton'}><img src={`./img/common/${nowId<=0?"left":"leftlight"}.png`} /></div>
                <div onClick={()=>{clickToBigChange(nowId+1)}} className={'rightButton'}><img src={`./img/common/${nowId>=(dataArray.length()-1)?"right":"rightlight"}.png`} /></div>
                {smallNavList}
            </div>
            {navList}
        </div>
    );
};

export default CarouselType1;