import React from 'react';
import './contactus.scss'
import {BrowserView, MobileView} from "react-device-detect";
import ContactusP from "./contactusP";
import ContactusM from "./contactusM";
const Contactus = () => {
    return (
        <>
            <BrowserView>
                <ContactusP/>
            </BrowserView>
            <MobileView>
                <ContactusM/>
            </MobileView>
        </>
    );
};

export default Contactus;