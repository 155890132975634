import React, {useEffect, useState} from 'react';
import {BrowserView, MobileView,isMobile} from "react-device-detect";
import PcNav from "./pc/pcNav/pcNav";
import Floor from "./pc/floor/floor";
import CanvasBg from "./pc/canvasbg/canvasBg";

import { matchRoutes, useLocation,Outlet } from "react-router-dom"
import MobileNav from "./mobile/mobileNav/mobileNav";
import useSize375 from "../common/useRem";
import MFloor from "./mobile/floor/mFloor";
import {AlertProvider} from "./mAlert/AlertContext";
import AlertPopup from "./mAlert/AlertPopup";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Navigation = () => {
    const routes = [{ path: "/" }];
    const location = useLocation();
    const [isIndex, setIsIndex] = useState(false);
    const mobxs=useSize375();//按设计稿375比例
    useEffect(()=>{
        window.scrollTo(0, 0);
        if(matchRoutes(routes, location)){
            setIsIndex(true);
        }else{
            setIsIndex(false);
        }
    },[location]);
    useEffect(()=>{
        if(isMobile){
            window.document.documentElement.style.fontSize = `${Math.round(window.innerWidth*13/375)}px`;
        }
    },[]);
    return (
        <AlertProvider>
            <div className={'bgFixed'}></div>
            <MobileView className={`contentMobile ${location.pathname.replace(/^\/+/, '').replace(/\/.*/, '').replace(/\W/g, '')}`}>
                <CanvasBg height={Math.round(mobxs*460)} topshow={isIndex}/>
                <MobileNav/>
                <Outlet/>
                <MFloor/>
            </MobileView>

            <BrowserView className={`${location.pathname.replace(/^\/+/, '').replace(/\/.*/, '').replace(/\W/g, '')}`}>
                <CanvasBg topshow={isIndex}/>
                <div className={'contentpc'}><PcNav/></div>
                <Outlet/>
                <div className={'contentpc'}><Floor/></div>
            </BrowserView>

            <AlertPopup/>
            <ToastContainer />
        </AlertProvider>
    );
};

export default Navigation;