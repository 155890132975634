import React, {useEffect, useMemo, useRef, useState} from 'react';
import BreathLight from "../../breathLight/breathLight";
import gsap from "gsap";
import useRem from "../../../common/useRem";

const ScaleAnimate = ({dataArray,i,nowId=0,imgwidth=1,imageheight=1}) => {
    const ref=useRef();
    const icondiv=useRef();
    const [selfSmallId, setSelfSmallId] = useState(0);
    const xs=useRem();
    const clickCallback=(id)=>{
        gsap.to(icondiv.current,{duration:.3,opacity:0});
        gsap.to(ref.current,{duration:.3,scale:1,delay:.3,onComplete:()=>{
            setSelfSmallId(id);
            gsap.to(ref.current,{duration:.3,delay:.5,scale:740/imgwidth});
            gsap.to(icondiv.current,{duration:.3,opacity:1,delay:1});
        }});

    }
    const html=useMemo(()=>{
        let smallary=[];
        for(let j=0;j<dataArray.smallLength(i);j++){
            smallary.push(
                <div
                    onClick={()=>clickCallback(j)} key={j}
                    style={{opacity:`${selfSmallId===j?1:.4}`,width:Math.floor(xs*145)}}
                    className={`changeIcon ${selfSmallId===j?"froce":""}`}>
                    <div className={'id'}>{j+1}</div>
                    <p>{dataArray.getSmallInfo(i,j).text}</p>
                </div>
            )
        }

        return(
            <>
                <div className={"content"} style={{width:imgwidth+8,height:imageheight+8}}>
                    <div className={'tipcontent'} style={{width:imgwidth, height:imageheight}}>
                        <div ref={ref} className={'mScale'} style={{
                            transform:`scale(${740/imgwidth})`,
                            transformOrigin:`${Math.floor(imgwidth/740*dataArray.getSmallInfo(i,selfSmallId).pos[0].x)}px ${Math.floor(imgwidth/740*dataArray.getSmallInfo(i,selfSmallId).pos[0].y)}px`}}>
                            <img alt={''} src={`${dataArray.getImageURL(i)}`} />
                        </div>
                    </div>
                    <div ref={icondiv}>
                        {dataArray.getSmallInfo(i,selfSmallId).pos.map((item,i)=>{
                            return(
                                <div key={i} style={{left:Math.floor(imgwidth/740*item.x), top:Math.floor(imgwidth/740*item.y)}} className={"tipAnimate"}>
                                    <BreathLight text={selfSmallId+1}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={'infoChangeScrollPar'}>
                    <div className={"infoChange"} style={{width:Math.floor(xs*145+8)*dataArray.smallLength(i)}}>
                        {smallary}
                    </div>
                </div>
            </>
        )
    },[selfSmallId]);
    return html;
};

export default ScaleAnimate;