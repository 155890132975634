import React from 'react';
import LightAnimate from "../../component/lightAnimate";
import AnimateScroll from "../../component/animateScroll/animateScroll";

const AutomationP = () => {
    return (
        <div className={'contentpc automationP'}>
            <AnimateScroll aniamteType={2}><div className={'bannerTextInfo'}>
                Elevate Your Options Trading to New Heights with SignalPlus's Fully Automated<br/>
                Trading and Risk Hedging Protocol
                <LightAnimate w={475} h={12} x={-5} y={227} />
            </div></AnimateScroll>
            <AnimateScroll aniamteType={3}><div className={'bannerTextField'}>SignalPlus provides a highly sophisticated and fully automated robot with advanced hedging capabilities, enabling efficient management of options trading books across multiple venues with minimal human intervention. These advanced services are available via API licensing, offering participants of all sizes to participate in the highly dynamic and ever-evolving crypto options market without the need to establish a complete trading desk.</div></AnimateScroll>
            <AnimateScroll aniamteType={3}><div className={'bannerTextField'}>With the help of our robots, you can now manage and execute in and out of your risks 24/7 without needing a full team of staff to work around the clock, effectively eliminating one of the major barriers to entry for options trading. Our sophisticated automation tools will enable a new generation of market participants to engage proficiently in options at a level-playing field, and allowing them to better focus on their trading thematics instead of mechanical nuances.</div></AnimateScroll>
            <AnimateScroll aniamteType={3}><div className={'bannerTextField'}>The hedging logic and execution records of our robot are fully transparent with examinable trails to ensure maximum visibility at all times. We are confident that our tools will be a game-changer for this space as we continue on our mission to democratize options trading for the everyday investor.
            </div></AnimateScroll>
            <div className={'fallFlow'}>
                <AnimateScroll aniamteType={2}><div className={'flowContent'}>
                    <div className={'leftFlow'} style={{backgroundImage:'url("./img/pc/fallflow.png")'}}>
                        <div className={'imageBox'}><img alt={''} src={'./img/pc/am0.png'} /></div>
                    </div>
                    <div className={'rightText'}>24/7 trading across all major venues with minimal human intervention</div>
                </div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'flowContent right'}>
                    <div className={'leftFlow'} style={{backgroundImage:'url("./img/pc/fallflow.png")'}}>
                        <div className={'imageBox'}><img alt={''} src={'./img/pc/am1.png'} /></div>
                    </div>
                    <div className={'rightText'}>200+ user-defined parameters to optimize your own risk-management methodologies</div>
                </div></AnimateScroll>
                <AnimateScroll aniamteType={2}><div className={'flowContent'}>
                    <div className={'leftFlow'} style={{backgroundImage:'url("./img/pc/fallflow.png")'}}>
                        <div className={'imageBox'}><img alt={''} src={'./img/pc/am2.png'} /></div>
                    </div>
                    <div className={'rightText'}>
                        Strong and proven trading performance in live-trading environments over various risk episodes
                    </div>
                    </div></AnimateScroll>
            </div>
            <AnimateScroll aniamteType={0}><div className={'emailInfo'}>Inquire <span>BD@signalplus.com</span> with the Team today to find out more!</div></AnimateScroll>
        </div>
    );
};

export default AutomationP;