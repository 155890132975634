import React, {useRef} from 'react';
import './animateButton.scss'
import {isMobile} from "react-device-detect";
import useRem from "../../common/useRem";
const AnimateButton = ({text,classname,href="#"}) => {
    const xs=useRem();
    return (
        <div className={`animateButton ${classname}`} style={{
            fontSize:isMobile?'1.5rem':16}}>
            <a style={{padding:isMobile?`${Math.floor(xs*16)}px ${Math.floor(xs*32)}px`:"8px 40px"}} href={href} className={'animated-button victoria-two'}>
                {text}
            </a>
        </div>
    );
};

export default AnimateButton;