import React from 'react';
import LightAnimate from "../../component/lightAnimate";
import useRem from "../../common/useRem";
import MCarousel from "../../component/mobile/mCarousel/mCarousel";
import {CarouselDataB} from "../../component/pc/pcCarousel/data";
import PaoMaDeng from "../../component/pc/paoMaDeng/paoMaDeng";
import AnimateScroll from "../../component/animateScroll/animateScroll";
import {isChinese} from "../../common/tool";


const IndexM = () => {
    const xs=useRem();
    return (
        <div className={'indexM'}>
            <div style={{overflow:"hidden"}}>
                <AnimateScroll aniamteType={2}><div className={'bannerTextInfoBig'}>
                    Democratizing Options for<br/> Digital Assets
                </div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'macbook'}><img src={'./img/pc/macbook.png'} alt={""} /></div></AnimateScroll>
                <AnimateScroll aniamteType={2}><div className={'bannerTextInfo'} style={{marginTop:xs*64}}>
                    Industry-leading<br/> Options Trading<br/><span><LightAnimate w={"100%"} h={xs*6} x={0} y={"88%"} />All-in-One</span><br/> Dashboard
                </div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'bannerTextField'}>
                    SignalPlus has produced a best-in-class dashboard with comprehensive crypto<br/> options pricing, analytics, liquidity<br/> aggregation and multi-vendor connectivity<br/> that satisfies the needs of professional<br/> traders with an intuitive and user-friendly<br/> interface – all free of charge.
                </div></AnimateScroll>
            </div>
            <MCarousel imgwidth={328}/>
            <div style={{overflow:"hidden"}}>
                <AnimateScroll aniamteType={2}><div className={'bannerTextInfo'} style={{marginTop:xs*64,textAlign:'center'}}>
                    <span><LightAnimate w={"100%"} h={xs*6} x={0} y={"88%"} />Advanced Automation</span> Tools
                </div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'bannerTextField'} style={{textAlign:'center'}}>
                    Take advantage of our comprehensive, battle-tested, Al-powered trading robot to fully automate your crypto options market making and delta hedging needs reliably regardless of market conditions.
                </div></AnimateScroll>
            </div>

            <MCarousel data={CarouselDataB} imgwidth={328}/>

            <div style={{overflow:"hidden"}}>
                <AnimateScroll aniamteType={2}><div className={'bannerTextInfo'} style={{marginTop:xs*64,textAlign:'center'}}>
                    <span><LightAnimate w={"100%"} h={xs*6} x={0} y={"88%"} />Structured Product</span> Pricer & Risk Management Engine
                </div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'bannerTextField'} style={{textAlign:'center'}}>
                    SignalPlus comprehensive structured product solution provides issuers with an effective and efficient way to manage risk through a fully automated hedging protocol. With limited human oversight required, issuers can rely on the wall street-grade pricer and in-house risk management engine to ensure the best risk transfer pricing, while the transparent model maximizes margin capture. The ability to support bespoke product design also allows issuers to tailor solutions to specific needs, further increasing efficiency. By utilizing this solution, structured product issuers can benefit from streamlined processes, reduced manual work, and access to the best risk models in the market, ultimately leading to improved performance and profitability.
                </div></AnimateScroll>

                <div className={'manageEngine'}>
                    <AnimateScroll aniamteType={2}><div className={"tip"}>
                        <div className={'icon'}><img alt={""} src={'./img/icon/1.png'} /></div>
                        <div className={'text'}>Wall Street- grade pricer,<br/> expandable to all popular TradFi<br/> structured products</div>
                    </div></AnimateScroll>
                    <AnimateScroll aniamteType={3}><div className={"tip"}>
                        <div className={'icon'}><img alt={""} src={'./img/icon/0.png'} /></div>
                        <div className={'text'}>In-house risk management<br/> engine ensures best risk transfer<br/> pricing</div>
                    </div></AnimateScroll>

                    <AnimateScroll aniamteType={2}><div className={"tip"}>
                        <div className={'icon'}><img alt={""} src={'./img/icon/2.png'} /></div>
                        <div className={'text big'}>Full model & pricing<br/> transparency that ensure<br/> maximum margin capture</div>
                    </div></AnimateScroll>
                    <AnimateScroll aniamteType={3}><div className={"tip"}>
                        <div className={'icon'}><img alt={""} src={'./img/icon/3.png'} /></div>
                        <div className={'text'}>Bespoke product design and<br/> terms tailored to particular needs</div>
                    </div></AnimateScroll>
                </div>

                <AnimateScroll aniamteType={2}><div className={'bannerTextInfo'} style={{marginTop:xs*64,textAlign:'center'}}>
                    SignalPlus <span><LightAnimate w={"100%"} h={xs*6} x={0} y={"88%"} />at a Glance</span>

                </div></AnimateScroll>

                <div className={"glance"}>
                    <AnimateScroll aniamteType={3}><div className={'glanceBox'}>
                        <div className={"icon"}><img alt={""} src={'./img/icon/12.png'} /></div>
                        <p>SignalPlus' Dashboard offers a comprehensive suite of professional-grade pricing, analytics, and cross-exchange trading tools to satisfy even the most discerning traders.</p>
                    </div></AnimateScroll>
                    <AnimateScroll aniamteType={2}><div className={'glanceBox'}>
                        <div className={"icon"}><img alt={""} src={'./img/icon/10.png'} /></div>
                        <p>A team composed of experts with diverse backgrounds from investment banks, technology leaders, and venture builders providing unparalleled support and insights to help our clients succeed</p>
                    </div></AnimateScroll>
                    <AnimateScroll aniamteType={3}><div className={'glanceBox'}>
                        <div className={"icon"}><img alt={""} src={'./img/icon/11.png'} /></div>
                        <p>Financial institutions, crypto-native funds, and options traders rely on our trusted platform to execute options strategies and manage risk in digital assets with ease.</p>
                    </div></AnimateScroll>
                </div>

                <AnimateScroll aniamteType={2}><div className={'bannerTextInfo'} style={{marginTop:xs*64,textAlign:'center'}}>
                    <span><LightAnimate w={"100%"} h={xs*6} x={0} y={"88%"} />Trusted</span> by Industry Leaders

                </div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'bannerTextField'} style={{textAlign:'center'}}>
                    SignalPlus is proud to be trusted by industry leaders and reputable digital assets firms as their partner of choice for crypto options. Our partnerships are built on trust, reliability, competence, and a shared commitment to innovation and long-term vision in the growth of this market. We maintain close and constant dialogue with our partners to ensure that our solution is meeting their ever expanding needs to provide the best options trading experience that industry has to offer. Please reach out to us and join our growing list of partners to build out our next steps together.
                </div></AnimateScroll>
            </div>
            <PaoMaDeng width={xs*(13024/5)-window.innerWidth}/>
            <div style={{overflow:"hidden"}}><AnimateScroll aniamteType={2}><div className={'bannerTextInfo'} style={{marginTop:xs*64,textAlign:'center'}}>
                    Latest <span><LightAnimate w={"100%"} h={xs*6} x={0} y={"88%"} />News</span> & <br/><span><LightAnimate w={"100%"} h={xs*6} x={0} y={"88%"} />Insights</span>
                </div></AnimateScroll>
                <div className={"latestNews"}>
                    <AnimateScroll aniamteType={3}><div className={'latestNewsBox'}>
                        <div className={"icon"}><img alt={''} src={'./img/icon/20.png'} /></div>
                        <p>Discover our University</p>
                        <a href={"#/content"}><div className={'more'}>Learn More<img alt={''} src={'./img/pc/jiantou.png'}/></div></a>
                    </div></AnimateScroll>
                    {/*<AnimateScroll aniamteType={2}><div className={'latestNewsBox'}>
                        <div className={"icon"}><img alt={''} src={'./img/icon/21.png'} /></div>
                        <p>What's New</p>
                        <a href={"#"}><div className={'more'}>Learn More<img alt={''} src={'./img/pc/jiantou.png'}/></div></a>
                    </div></AnimateScroll>*/}
                </div>
            </div>
        </div>
    );
};

export default IndexM;